import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import { StaticRouter, useNavigate, useLocation } from 'react-router-dom';
import {
  Grid, Hidden, Typography, Box
} from '@mui/material';
import toast from 'react-hot-toast';
import Returns from './returns';
import { parseJson } from '../../affiliates/utils';
import MainContent from '../../customComponents/mainContent';
import ReturnSelectField from '../../suppliers/individual/returnSelectField';
import ReasonSelectBoxField from './returnReasonOptions';
import {
  BackArrowIconContainer, PageTitleText, PageTitleSubText, DeliveryAddressCardHeader,
  DeliveryAddressCardHeaderTitle, DeliveryAddressCardHeaderSubTitle, PageTitleContainer,
  KeyboardArrowLeft, ChangeOutletRow, SelectOutletText, OutletInfoContainer, OutletContactLine,
} from '../../cart/cart.styles';

import {
  CancelReturnButton, CancelReturnButtonText, ReturnSelectedOrderButton, ButtonGrid, CartGridContainer,
  ReturnSelectedOrderButtonText, AddDeliveryButton, AddDeliveryButtonText, CartGridContainerHeader,
  DeliveryAddressCard, ButtonBox
} from './returns.styles';

import { SupplierDetailCardWrapper } from '../supplierorder-detail/styles';
import { OgaToken } from '../../../graphql/token';
import { handleDownloadUtil } from '../../../utils/utils';
import { DeliveryAddressCardSection } from '../dispatch/dispatchContainer.styles';

import OrderInfoCard from '../../shared/orderInfoCard/orderInfoCard';
import AddLocationDialog from './addLocationDialog/addLocationDialog';
import CancelReturnDialog from './cancelReturnDialog/cancelReturnDialog';
import { RETURN_ORDER } from '../../../mutations/orders';

const ReturnsContainer = (props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const initialState = location?.state;

  const [state, setState] = useState(initialState);
  const [openDialog, setOpenDialog] = useState(false);
  const [returnDialog, setReturnDialog] = useState(false);
  const [returnOrder] = useMutation(RETURN_ORDER);

  useEffect(() => {
    const data = location?.state;
    if (!data) {
      navigate('/orders-admin');
    }
    // append initial quantity to return to all items in cart
    setState({ ...state, cart: state?.cart.map((cartItem) => ({ ...cartItem, quantityReturned: 0 })) });
  }, []);

  const handleChangeReason = (id, value, key) => {
    const { cart } = state;
    const existingCartItem = cart.find((item) => item.id === id);
    if (!existingCartItem.recieved && key !== 'recieved') return;
    setState({
      ...state,
      cart: cart.map((cartItem) => (cartItem.id === existingCartItem.id
        ? { ...cartItem, [key]: value }
        : cartItem))
    });
  };

  const handleNewBatches = (batch, id, value) => {
    const { editReturn } = state;
    let match = batch.filter((val) => Object.keys(val).includes('batchId'));
    if (editReturn) {
      match = [];
      setState({ ...state, editReturn: false });
    }
    const existingId = match.filter((val) => val.batchId === id);
    if (existingId.length) {
      let updateBatch = match.filter((val) => val.batchId !== id);
      if (editReturn) {
        updateBatch = [];
        setState({ ...state, editReturn: false });
      }
      const batches = [...updateBatch, { batchId: id, qtyToReturn: value }];
      return batches;
    }
    const batches = [...match, { batchId: id, qtyToReturn: value }];
    return batches;
  };

  const handleBatchUpdate = (id, value, rowId) => {
    if (id && value) {
      const { cart } = state;
      const existingCartItem = cart.find((item) => item.id === rowId);
      setState({
        ...state,
        cart: cart.map((cartItem) => (cartItem.id === existingCartItem.id
          ? { ...cartItem, batches: handleNewBatches(cartItem.batches, id, value) }
          : cartItem))
      });
    }
  };

  const handleCloseDialog = () => {
    setOpenDialog(!openDialog);
  };

  const handleReturnDialog = () => {
    setReturnDialog(!returnDialog);
  };

  const handleIncrementProductQuantityInCart = (id) => {
    const { cart } = state;

    const existingCartItem = cart.find((item) => item.id === id);
    if (!existingCartItem.recieved) return;

    if (existingCartItem.quantityReturned < existingCartItem.quantityRecieved) {
      setState({
        ...state,
        cart: cart.map((cartItem) => (cartItem.id === existingCartItem.id
          ? { ...cartItem, quantityReturned: cartItem.quantityReturned + 1 }
          : cartItem))
      });
    }
  };

  const handleChangeProductQuantityInputValue = (id, value) => {
    const { cart } = state;
    let newValue = 0;
    if (Number(value)) newValue = value;
    else newValue = 1;

    const existingCartItem = cart.find((item) => item.id === id);
    if (!existingCartItem.recieved) return;
    setState({
      ...state,
      cart: cart.map((cartItem) => (cartItem.id === existingCartItem.id
        ? { ...cartItem, quantityReturned: newValue }
        : cartItem))
    });
  };

  const handleBatchInputValue = (id, value) => {
    const { cart } = state;
    let newValue = 0;
    if (Number(value)) newValue = value;
    else newValue = 1;

    const existingCartItem = cart.find((item) => item.id === id);
    setState({
      ...state,
      cart: cart.map((cartItem) => (cartItem.id === existingCartItem.id
        ? { ...cartItem, quantityReturned: value }
        : cartItem))
    });
  };

  const handleDecrementProductQuantityInCart = (id) => {
    const { cart } = state;
    const existingCartItem = cart.find(
      (cartItem) => cartItem.id === id
    );
    if (!existingCartItem.recieved) return;

    if (existingCartItem.quantityRecieved >= 1 && existingCartItem.quantityReturned > 0) {
      setState({
        ...state,
        cart: cart.map((cartItem) => (cartItem.id === existingCartItem.id
          ? { ...cartItem, quantityReturned: cartItem.quantityReturned - 1 }
          : cartItem))
      });
    }
  };
  const [changeAlarm, setChangeAlarm] = useState(false);
  const handleDeliveryChange = (event) => {
    state.selectedDeliveryLocation = event.target.value;
    setChangeAlarm(!changeAlarm);
  };

  const getDeliveryLocationOption = () => {
    const optionsBox = { name: 'deliverTo', label: 'Deliver To', options: [] };
    const { deliveryLocations } = state;
    deliveryLocations.forEach((deliveryLocation) => {
      let { contacts } = deliveryLocation;
      try {
        contacts = parseJson(contacts.replace(/'/g, '"'));
      } catch (e) {
        contacts = JSON.stringify(contacts);
        contacts = parseJson(contacts.replace(/'/g, '"'));
      }
      optionsBox.options.push(`${contacts.address_line_1}, ${contacts.city}, ${contacts.country}`);
    });
    return optionsBox;
  };

  const getSelectedDeliveryLocationInfo = () => {
    const { selectedDeliveryLocation, deliveryLocations } = state;
    const delivery = deliveryLocations.find((deliveryLocation) => {
      let { contacts } = deliveryLocation;
      try {
        contacts = parseJson(contacts.replace(/'/g, '"'));
      } catch (e) {
        contacts = JSON.stringify(contacts);
        contacts = parseJson(contacts.replace(/'/g, '"'));
      }
      const contactAdress = `${contacts.address_line_1}, ${contacts.city}, ${contacts.country}`;
      return contactAdress === selectedDeliveryLocation || deliveryLocation.name === selectedDeliveryLocation;
    });
    let { contacts } = delivery || deliveryLocations[0];
    try {
      contacts = parseJson(contacts.replace(/'/g, '"'));
    } catch (e) {
      contacts = JSON.stringify(contacts);
      contacts = parseJson(contacts.replace(/'/g, '"'));
    }
    return `${contacts.address_line_1}, ${contacts.city}, ${contacts.country}`;
  };

  useEffect(() => {
    getDeliveryLocationOption();
    getSelectedDeliveryLocationInfo();
  }, [changeAlarm]);

  const renderSelectOutletInfo = () => {
    const selectedDelivryLocationInfo = getSelectedDeliveryLocationInfo();
    if (selectedDelivryLocationInfo) {
      return (
        <OutletInfoContainer>
          <OutletContactLine><b>Delivery Address</b></OutletContactLine>
          <br />
          <OutletContactLine>
            {selectedDelivryLocationInfo}
          </OutletContactLine>
        </OutletInfoContainer>
      );
    }
    return null;
  };

  const handleReturnAllOrders = () => {
    const { cart } = state;
    navigate({
      pathname: '/return-order/review',
      state: {
        ...state,
        cart: cart.map((cartItem) => (!ReasonSelectBoxField.options.includes(cartItem.condition)
          ? { ...cartItem, condition: 'Not specified' }
          : cartItem))
          .map((cartItem) => ({ ...cartItem, quantityReturned: cartItem.quantityRecieved }))
      }
    });
  };

  const handleReturnSelectedOrdersFromSupplier = (selectedSuppliers) => {
    const { cart } = state;

    localStorage.removeItem('selectedSupplier');
    navigate({
      pathname: '/return-order/review',
      state: {
        ...state,
        cart: cart.filter((cartItem) => (selectedSuppliers.includes(cartItem.supplier)))
          .map((cartItem) => (!ReasonSelectBoxField.options.includes(cartItem.condition)
            ? { ...cartItem, condition: 'Not specified' }
            : cartItem))
          .map((cartItem) => ({ ...cartItem, quantityReturned: cartItem.quantityRecieved }))
      }
    });
  };

  const handleChangeDeliveryLocation = (newAddress) => {
    state.deliveryLocations = newAddress;
    setChangeAlarm(!changeAlarm);
  };

  const handleSubmitReturn = (cart) => {
    const products = cart.map((productItem) => ({
      productId: productItem.id,
      returningQuantity: productItem.quantityReturned,
      reason: productItem.reason,
      batches: productItem.batches
    }));
    if (!products.length) return toast.error('Sorry, cannot return an empty product');
    returnOrder({
      variables: {
        returnOrderId: state.id,
        products,
      }
    }).then((response) => true).catch((err) => {
      toast.error(err?.message);
      return false;
    });
  };

  const handleReturnSelectedOrders = () => {
    const selectedSuppliers = JSON.parse(localStorage.getItem('selectedSupplier'));
    const { cart } = state;
    // if (selectedSuppliers.length) {
    //   return handleReturnSelectedOrdersFromSupplier(selectedSuppliers);
    // }
    // eslint-disable-next-line array-callback-return
    const filteredCart = cart.filter((cartItem) => {
      const { quantityReturned, quantityRecieved } = cartItem;
      // check if either quantity or reason was changed
      if (quantityReturned && quantityReturned !== 0 && quantityReturned <= quantityRecieved) {
        return cartItem;
      }
    }).map((cartItem) => {
      const { reason } = cartItem;
      return (!reason) ? { ...cartItem, reason: 'Not specified' } : cartItem;
    });

    if (!filteredCart.length) {
      toast.error('Select order to return');
      return;
    }

    handleSubmitReturn(filteredCart);

    navigate('/return-order/review', {
      state: {
        ...state,
        cart: filteredCart
      }
    });
  };

  const token = localStorage.getItem('oga_rest_token');


  const downloadWaybill = async () => {
    try {
      const { id } = state;
      await handleDownloadUtil(OgaToken.SERVER_LINK, 'download-waybill-invoice', token, `${id}.pdf`);
    } catch (err) {
      toast.error('Invoice download failed');
      console.error('Invoice download failed:', err);
    }
  };

  return state
    ? (
      <MainContent>
        <CartGridContainer container>
          <BackArrowIconContainer
            style={{ marginRight: 'auto' }}
            onClick={() => navigate('/orders-admin')}
          >
            <KeyboardArrowLeft />
            <Typography>back</Typography>
          </BackArrowIconContainer>
          <CartGridContainerHeader container>
            <Grid item md={7}>
              <PageTitleContainer>
                <PageTitleText>Initiate Order Return</PageTitleText>
                <PageTitleSubText>Specify portion of order to return</PageTitleSubText>
              </PageTitleContainer>
            </Grid>
            {!state.orderIsReturned ? (
              <Grid item md={5} style={{ paddingBottom: '1.5rem' }}>
                <ButtonBox>
                  <CancelReturnButton onClick={() => setReturnDialog(true)}>
                    <CancelReturnButtonText>Cancel Return</CancelReturnButtonText>
                  </CancelReturnButton>
                  {/* <CancelReturnButton>
                    <CancelReturnButtonText onClick={downloadWaybill}>Generate Waybill</CancelReturnButtonText>
                  </CancelReturnButton> */}
                  <ReturnSelectedOrderButton
                    onClick={handleReturnSelectedOrders}
                  >
                    <ReturnSelectedOrderButtonText>
                      Return Selected
                    </ReturnSelectedOrderButtonText>
                  </ReturnSelectedOrderButton>
                </ButtonBox>
                {/* <ReturnOrderButton
                  onClick={() => handleReturnAllOrders()}
                >
                  <Hidden smDown>
                    <ArrangeReturnIcon />
                  </Hidden>
                  <ReturnOrderButtonText>Return All Supplier Orders</ReturnOrderButtonText>
                </ReturnOrderButton> */}
              </Grid>
            )
              : (
                <Grid item md={2} style={{ paddingBottom: '1.5rem' }}>
                  <ReturnSelectedOrderButton onClick={() => navigate(`/orders-admin/dispatch/${state.parentOrderId}`)}>
                    <ReturnSelectedOrderButtonText>
                      Parent Order
                    </ReturnSelectedOrderButtonText>
                  </ReturnSelectedOrderButton>
                </Grid>
              )}
          </CartGridContainerHeader>
          <Grid
            container
            justifyContent="space-between"
            spacing={3}
          >
            <SupplierDetailCardWrapper lg={6} container item>
              <OrderInfoCard
                id={String(state.id).padStart(6, 0)}
                business={state}
                dateCreated={state.businessDateCreated}
                businessDateDelivered={state.businessDateDelivered}
                fulfilledBy={localStorage.getItem('oga_username')}
                returns
                orderIsReturned={state.orderIsReturned && true}
                returnOrderId={state.parentOrderId}
              />
            </SupplierDetailCardWrapper>

            <DeliveryAddressCard item md={12} lg={6}>
              <DeliveryAddressCardSection elevation={2}>
                <DeliveryAddressCardHeader>
                  <DeliveryAddressCardHeaderTitle>Delivery Location</DeliveryAddressCardHeaderTitle>
                  {!state.orderIsReturned && (
                    <AddDeliveryButton onClick={() => setOpenDialog(true)} disabled={state.orderIsReturned}>
                      <AddDeliveryButtonText>Add Delivery Location</AddDeliveryButtonText>
                    </AddDeliveryButton>
                  )}
                  {/* <DeliveryAddressCardHeaderSubTitle
                    type="button"
                  >
                    Add Outlet
                  </DeliveryAddressCardHeaderSubTitle> */}
                </DeliveryAddressCardHeader>
                {!state.orderIsReturned ? (
                  <Grid style={{ marginBottom: '20px' }}>
                    <SelectOutletText>
                      Select the outlet you want your order to be
                      <br />
                      delivered to from the dropdown below
                    </SelectOutletText>
                    <ChangeOutletRow>
                      <ReturnSelectField
                        field={getDeliveryLocationOption()}
                        value={getSelectedDeliveryLocationInfo()}
                        showCheckBox={false}
                        handleChange={handleDeliveryChange}
                        label=""
                        fullWidth
                        returns
                      />
                    </ChangeOutletRow>
                  </Grid>
                )
                  : (
                    <Grid style={{ marginBottom: '20px' }}>
                      <SelectOutletText>
                        The outlet the order was delivered to
                      </SelectOutletText>
                    </Grid>
                  )}
                {renderSelectOutletInfo()}
              </DeliveryAddressCardSection>
            </DeliveryAddressCard>
          </Grid>
          <Returns
            state={state}
            handleChangeReason={handleChangeReason}
            handleIncrement={handleIncrementProductQuantityInCart}
            handleDecrement={handleDecrementProductQuantityInCart}
            handleChangeProductQuantity={handleChangeProductQuantityInputValue}
            disableField={state.orderIsReturned}
            handleChange={handleBatchInputValue}
            handleBatchUpdate={handleBatchUpdate}
          />
          <AddLocationDialog
            open={openDialog}
            close={handleCloseDialog}
            currentState={state}
            changeDelivery={(newAddress) => handleChangeDeliveryLocation(newAddress)}
          />
          <CancelReturnDialog
            open={returnDialog}
            close={handleReturnDialog}
            editReturn={state.editReturn}
            orderId={state.id}
          />
        </CartGridContainer>
      </MainContent>
    ) : '';
};

ReturnsContainer.propTypes = {
};

ReturnsContainer.defaultProps = {
};

export default ReturnsContainer;
