import React from 'react';
import {
  Dialog,
  DialogContentText,
  Slide,
  Grid,
  Typography,
  TableContainer,
  Box
} from '@mui/material';
import { PrintOutlined, EmailRounded, Close } from '@mui/icons-material';
import PropTypes from 'prop-types';
import toast from 'react-hot-toast';
import currencyFormatter from '../../../../../utils/currencyFormatter';
import { OgaToken } from '../../../../../graphql/token';
import { handleDownloadUtil } from '../../../../../utils/utils';
import { JSONParse } from '../../../../../utils/json';
import {
  CardTitle,
  DialogContentStyled,
  Hbg,
  HbgSmall,
  CustomerDetails,
  CustomerDetail,
  DefaultTable,
  DefaultTrHead,
  DefaultTd,
  DefaultTr,
  DefaultTdBody,
  Separator,
  CustomerDetailLt,
  ReceiptFooter,
  SmallText,
  ButtonDarkStyled,
  ButtonWhiteStyled,
  ButtonGrayStyled
} from '../return-details.style';

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

export default function ReturnReceipt({
  sale,
  returns,
  saleReturnData,
  customer,
  openReturnDialog,
  openReturnReceiptHandler
}) {
  const { businessUser, receiptNumber } = saleReturnData;
  const date = new Date().toLocaleDateString();
  const { business, user } = businessUser;
  const { contacts = '' } = business || {};
  const { mobile_number: mobileNumber } = JSONParse(contacts) || {};
  const { last_name: lastName, first_name: firstName } = JSONParse(user?.profile) || {};

  const getTime = () => {
    const dt = new Date();
    let hours = dt.getHours();
    const AmOrPm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12 || 12;
    const minutes = dt.getMinutes();
    return `${hours}:${minutes} ${AmOrPm}`;
  };

  const {
    sale: {
      discount,
      changeDue,
      total,
    }
  } = sale;


  const token = localStorage.getItem('oga_rest_token');

  const handlePrint = async () => {
    try {
      await handleDownloadUtil(OgaToken.SERVER_LINK, 'download_receipt', token, `${receiptNumber}.pdf`, 'return');
    } catch (err) {
      toast.error('Receipt download failed');
      console.error('download failed:', err);
    }
  };

  const getItemPrice = (qty, unitcost) => qty * unitcost;

  const getDiscount = () => {
    const result = discount / (total / 100);
    return result || 0;
  };

  return (
    <div>
      <Dialog
        open={openReturnDialog}
        TransitionComponent={Transition}
        keepMounted
        onClose={openReturnReceiptHandler}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContentStyled>
          <DialogContentText>
            <Grid style={{ marginTop: '1rem' }}>
              <Box display="flex" justifyContent="space-between">
                <CardTitle>Return Receipt</CardTitle>
                <Close
                  fontSize="small"
                  onClick={openReturnReceiptHandler}
                  style={{ cursor: 'pointer' }}
                />
              </Box>
            </Grid>
            <Hbg>
              <Typography style={{ fontWeight: 'bold' }}>
                Receipt #
                {receiptNumber}
              </Typography>
              <HbgSmall>
                {date}
                {' '}
                {getTime()}
              </HbgSmall>
            </Hbg>

            <CustomerDetails>
              <CustomerDetail>Customer Name:</CustomerDetail>
              <CustomerDetail>{(customer && customer?.name) || 'N/A'}</CustomerDetail>
            </CustomerDetails>
            <CustomerDetails>
              <CustomerDetail>Phone Number:</CustomerDetail>
              <CustomerDetail>{mobileNumber}</CustomerDetail>
            </CustomerDetails>
            <TableContainer style={{ marginTop: '1rem' }}>
              <DefaultTable>
                <DefaultTrHead>
                  <DefaultTd>Return Product Name</DefaultTd>
                  <DefaultTd>Qty</DefaultTd>
                  <DefaultTd>Price</DefaultTd>
                </DefaultTrHead>
                {returns.map((item) => (
                  <DefaultTr>
                    <DefaultTdBody>{item.name}</DefaultTdBody>
                    <DefaultTdBody>
                      1 x
                      {' '}
                      {item.quantity}
                    </DefaultTdBody>
                    {/* <DefaultTdBody>
                      1 {`${item.quantity} x ${currencyFormatter(
                        item.unitCost
                      )}`}
                    </DefaultTdBody> */}
                    <DefaultTdBody>
                      {currencyFormatter(Math.abs(getItemPrice(item.quantity, item.unitCost)))}
                    </DefaultTdBody>
                  </DefaultTr>
                ))}
                <DefaultTr>
                  <DefaultTdBody>Sub Total</DefaultTdBody>
                  <DefaultTdBody />
                  <DefaultTdBody>{currencyFormatter(total)}</DefaultTdBody>
                </DefaultTr>
                <DefaultTr>
                  <DefaultTdBody>
                    {getDiscount()}
                    % Discount
                  </DefaultTdBody>
                  <DefaultTdBody />
                  <DefaultTdBody>{currencyFormatter(discount)}</DefaultTdBody>
                </DefaultTr>
              </DefaultTable>
              <Separator />
              <CustomerDetails>
                <CustomerDetail>Amount to Refund</CustomerDetail>
                <CustomerDetail>{ currencyFormatter(Math.abs(returns.reduce((a, b) => a + b.total, 0))) }</CustomerDetail>
              </CustomerDetails>
              <CustomerDetails>
                <CustomerDetailLt>Change Due</CustomerDetailLt>
                <CustomerDetailLt>{ currencyFormatter(changeDue) }</CustomerDetailLt>
              </CustomerDetails>
              <CustomerDetails>
                <CustomerDetail>Payment Method</CustomerDetail>
              </CustomerDetails>
              <CustomerDetails>
                <CustomerDetailLt>Cash</CustomerDetailLt>
                <CustomerDetail>{ currencyFormatter(total) }</CustomerDetail>
              </CustomerDetails>
              <Separator />
              <ReceiptFooter>
                <SmallText>Your Cashier Today</SmallText>
                <Typography
                  variant="body2"
                  style={{ fontWeight: 'bold', padding: '.5rem 0' }}
                >
                  {firstName ? `${firstName} ${lastName}` : user.username}
                </Typography>
                <SmallText>
                  <i>Thank You For Shopping With Us. Please Come Again.</i>
                </SmallText>
              </ReceiptFooter>

              <Separator />

              <Grid
                style={{
                  display: 'flex',
                  gap: '.3rem',
                  marginTop: '2rem',
                  padding: '0 2rem'
                }}
                alignItems="center"
                justifyContent="space-between"
              >
                <ButtonWhiteStyled onClick={openReturnReceiptHandler}>Close Transaction</ButtonWhiteStyled>
                <ButtonGrayStyled disabled>
                  <EmailRounded
                    fontSize="small"
                    style={{ marginRight: '.5rem' }}
                  />
                  <span>Send To Email</span>
                </ButtonGrayStyled>
                <ButtonDarkStyled onClick={handlePrint}>
                  <PrintOutlined
                    fontSize="small"
                    style={{ marginRight: '.5rem' }}
                  />
                  <span>Print Receipt</span>
                </ButtonDarkStyled>
              </Grid>
            </TableContainer>
          </DialogContentText>
        </DialogContentStyled>
      </Dialog>
    </div>
  );
}

ReturnReceipt.propTypes = {
  sale: PropTypes.instanceOf(Object).isRequired,
  returns: PropTypes.instanceOf(Object).isRequired,
  saleReturnData: PropTypes.instanceOf(Object).isRequired,
  customer: PropTypes.instanceOf(Object).isRequired,
  openReturnDialog: PropTypes.isRequired,
  openReturnReceiptHandler: PropTypes.isRequired
};
