import React from 'react';
import {
  Typography,
  IconButton,
  Table,
  TableHead,
  TableContainer,
  TableCell,
  TableRow
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { styled } from '@mui/material/styles';
import { css } from '@emotion/react';

const primaryStyles = css`
  background-color: #fced50;
  color: #424242;

  &:hover {
    background-color: #d2c647;
  }
`;

export const CardTitle = styled('span')`
  font-weight: 600;
  color: #303030;
  font-size: 18px;
`;

export const TableHeadStyled = styled(TableHead)`
  background: #F3F9FF;
`;

export const TableRowStyled = styled(TableRow)`
  overflow-x: scroll;
`;

export const TableCellStyled = styled(TableCell)`
  font-size: 15px;
  font-weight: 600;
  white-space: nowrap;
  border-bottom: none;
`;

export const StatContainer = styled('div')`
  display: flex;
  gap: 1.3rem;
  justify-content: space-around;
  width: 100%;
  border-top: 1px solid #f4f5f6;
`;

export const Stat = styled('div')`
  white-space: nowrap;
  font-size: 17px;
  font-weight: bold;
  border-bottom: none;
  color: #606060;
  padding-top: .4rem;
`;

export const TableCellBorderStyled = styled(TableCell)`
  border-top: 1px solid #f4f5f6;
  white-space: nowrap;
  font-size: 15px;
  border-bottom: none;
`;

export const TableCellBodyStyled = styled(TableCell)`
  white-space: nowrap;
  font-size: 15px;
  border-bottom: none;
`;

const secondaryStyles = css`
  background-color: none;
  color: #424242;
  border: 1px solid black;
  margin-right: 2rem;
`;

const getButtonStyles = ({ type }) => {
  switch (type) {
    case 'secondary':
      return secondaryStyles;
    default:
      return primaryStyles;
  }
};

export const OrderButton = styled(({ color, ...otherProps }) => (
  <IconButton
    {...otherProps}
    classes={{ disabled: 'disabled', label: 'label' }}
  />
))`
  height: 80px;
  display: flex;
  align-items: center;
  padding: 0px 25px;
  border-radius: 15px;
  margin-bottom: 0.5rem;

  &.disabled {
    color: #848483;
    border: 1px solid #cccccb;
  }

  @media (min-width: 992px) {
    height: 40px;
    padding: 0px 10px;
    border-radius: 10px;
  }

  ${getButtonStyles}
`;

export const PlusIcon = styled(AddIcon)`
  font-size: 3.5rem;

  @media (min-width: 992px) {
    font-size: 1.5rem;
  }
`;

export const ButtonText = styled(Typography)`
  font-size: 16px;
  font-weight: 500;
  margin-left: 6px;
`;

export const MainTableHead = styled(TableHead)`
  display: none;

  @media (min-width: 992px) {
    display: table-header-group;
  }
`;
export const TableContainerWrapper = styled(TableContainer)``;

export const TableComponent = styled(Table)`
  min-width: 650px;
  border-radius: 10px;
  background: white;
`;

export const HeaderCell = styled(TableCell)`
  font-size: 1.2rem;
  font-weight: 600;
  line-height: 2.5rem;
  border-bottom: 2px solid rgba(224, 224, 224, 1);

  &:nth-child(even) {
    padding: 0;
  }
`;
