import { styled } from '@mui/material/styles';
import { css } from '@emotion/react';
import {
  Table, TableCell, TableHead, TableRow,
  Typography, Grid, IconButton, Paper, Button, Box
} from '@mui/material';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import { ExportIcon } from '../../assets/svgs';
import CustomButton from '../customComponents/customButton';

export const TCustomButton = styled(Button)`
  border: 1px solid #303030;
  border-radius: 8px;
  box-shadow: 0px 4px 14px rgba(151, 151, 151, 0.19);
  height: 3rem;
  width: 6.5rem;
  margin-left: 0.8rem;
  color: #7b7b7b;
  font-weight: 500;
  font-size: 14px;

  @media (max-width: 991px) {
    margin-top: 1rem;
    margin-left: 0;
    width: 60rem;
    height: 5rem;
    font-size: 35px;
    border-radius: 20px;
    border: 1px solid #235A91;
    color:  #235A91;
    font-weight: 600;
  }
`;

export const NCustomButton = styled(CustomButton)`
  color: #606060;
  height: 47px;
  border: 1px solid rgb(186, 186, 186);
  box-shadow: 0px 4px 14px rgb(219 219 219 / 19%);
  border-radius: 8px;
  width: 100%;

  & .label {
    font-weight: 500;
    font-size: 0.75rem;
  }
`;

export const CIconButton = styled(IconButton)`
  width: 2rem;
  height: 2rem;
  padding: 0;
  margin-right: 21.64px;
  color: #235A91;
  font-size: 14px;

  @media(min-width: 992px) {
    margin-right: 5px;
  }
`;

export const ExportIconStyled = styled(ExportIcon)`
  font-size: 1.5rem;
  color: '#606060';
  margin-right: 5px;
`;

export const StatisticsGrid = styled(Grid)`
  overflow: hidden;
  margin-top: 5rem;
  margin-bottom: 3rem;
  padding-bottom: 5px;
  align-items: center;

  @media (min-width: 992px) {
    margin-top: 1.5rem;
    margin-bottom: 1rem;
  }
`;

export const PaperWrapper = styled(Paper)`
  border-radius: 10px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
`;

export const SearchGrid = styled(Grid)`
  padding: 2rem 1.5rem .5rem;
  align-items: center;
`;

export const TableGrid = styled(Grid)`
  margin:  1.5rem;
  overflow-x: auto;
`;

export const TableHeader = styled(TableCell)`
  /**text-transform: capitalize;**/
  font-size: 1rem;
  font-weight: 600;
  line-height: 2.8;
  padding: 10px;
  border-bottom: 0;
  background: #F3F9FF;

  @media(min-width: 992px) {
    &:nth-child(1), &:nth-child(3), &:nth-child(4) {
      width: 24%;
    }
    &:nth-child(2) {
      width: 28%;
    }
  }
`;

export const MainTableRow = styled(TableRow)`
  cursor: pointer;

  & > * {
    border-bottom: unset;
  }
  :nth-child(2n) {
    background: #F3F9FF;
  }
`;

export const BodyCell = styled(TableCell)`
  font-size: 16px;
  line-height: 2.5;
  color: #606060;
  padding: 10px;
  overflow: hidden;
  text-overflow: ellipsis;

  @media(min-width: 992px) {
    &:nth-child(1), &:nth-child(4) {
      width: 22%;
    }
    &:nth-child(2), &:nth-child(3) {
      width: 28%;
    }
  }
`;

export const FooterWrapper = styled(Grid)`
  padding: 1.5rem;
`;

export const DetailCard = styled(Paper)`
  width: 100%;
  padding: 30px;
  margin: 2rem 0;
  border-radius: 12px;
`;
export const DetailCardHeaderGrid = styled(Grid)`
  border-bottom: 2px solid rgba(224, 224, 224, 1);
  display: flex;
  justify-content: space-between;
  margin-bottom: 1em;
`;
export const DetailCardHeaderText = styled('p')`
  font-weight: 600;
  font-size: 16px;
`;
export const DetailCardText = styled(Typography)`
  font-size: 16px;
  font-weight: 500;
  text-transform: capitalize;
  color: #606060;
  display: inline-flex;
  align-items: center;

  @media(max-width: 992px) {
    margin-left: 15rem;
  }
`;
export const PaperCardGrid = styled(Grid)`
  height: 100%;
  flex-wrap: nowrap;
  align-items: center;
`;

export const StatusBullet = styled('div')`
  border-radius: 50%;
  width: 6px;
  height: 6px;
  margin-left: 8px;
  background-color: ${({ color }) => color};
  display: inline-block;

  @media(max-width: 991px) {
    width: 1rem;
    height: 1rem;
  }
`;

export const TableComponent = styled(Table)`
`;

export const MainTableHead = styled(TableHead)`
  display: none;

  @media(min-width: 992px) {
    display: table-header-group;
  }
`;

export const TitleGridContainer = styled(Grid)`
  justify-content: space-between;
  align-items: center;
`;

export const TextWrapper = styled(Grid)`
`;

export const EditReportIcon = styled('img')`
  width: 30px;
  margin-bottom: 4px;

  @media(min-width: 992px) {
    width: 17px;
  }
`;

export const TitleTextGridContainer = styled(Grid)`
  align-items: center;
  flex-direction: column;
`;

export const PrimaryTitle = styled(Typography)`
  font-size: 3rem;
  font-weight: 700;
  // color: #303030;
  color: black;

  @media(min-width: 992px) {
    font-size: 24px;
  }
`;

export const SecondaryTitle = styled(Typography)`
  font-size: 16px;
  font-weight: 500;
  color: #606060;

  @media(max-width: 991px) {
    display: none;
  }
`;

export const IconsGridContainer = styled(Grid)`
  justify-content: flex-end;
`;

export const TabIcon = styled(IconButton)`
  color: #424242;
  margin-left: 3rem;
`;

export const TabImage = styled('img')`
  width: 26px;
`;

const completeStyles = css`
  background-color: #DCFEDD;
  color: #07DE10;
`;

const reconcileStyles = css`
  background-color: #FBF777;
  color: #606060;
`;

const getStyles = ({ status }) => {
  switch (status) {
    case 'complete':
      return completeStyles;
    default:
      return reconcileStyles;
  }
};

export const ColorGrid = styled(Grid)`
  border-radius: 7px;
  text-transform: capitalize;
  font-size: 16px;
  font-weight: 500;
  color: #606060;
  justify-content: center;
  padding: 4px 0;
  width: 50%;

  ${getStyles}
`;

export const KeyboardArrowLeft = styled(KeyboardArrowLeftIcon)`
  color: #235A91;
  font-size: 4rem;

  @media(min-width: 992px) {
    font-size: 1.7rem;
  }
`;

export const StatusColor = styled(Box)`
  width: 0.563rem;
  height: 0.563rem;
  background: ${({ active }) => (active ? '#00B588' : '#F29053')};
  border-radius: 50%;
  margin-top: 7px;

  @media(max-width: 991px) {
    // margin-left: 13.5rem;
    width: 1rem;
    height: 1rem;
    margin-top: 12px;

  }
`;

export const StatusText = styled(Typography)`
  margin-left: 10px;
  text-transform: capitalize;

  @media(max-width: 991px) {
    font-size: 1.5rem
  }
`;

export const FilterImg = styled('img')`
  height: 5rem;
  width: 5rem;
`;

export const MobileContainer = styled(Paper)`
  margin-bottom: 2rem;
  border-radius: 2rem;
  padding: 1rem;
  width: 100%;
  box-shadow: 0px 2.96px 66.64px rgba(163, 171, 185, 0.24);
  border-radius: 18px;
`;

export const DateText = styled(Grid)`
  font-weight: 600;
  font-size: 2.5rem;
  padding: 2rem;
`;

export const HeaderText = styled(Grid)`
  font-weight: 600;
  font-size: 2rem;
`;

export const DetailText = styled(Grid)`
  text-align: right;
  font-size: 2rem;
  overflow-wrap: anywhere;
`;

export const TextBox = styled(Grid)`
  padding: 0 2rem 2rem 2rem;
`;

export const ContentBox = styled(Box)`
  margin-top: 1rem;
`;
