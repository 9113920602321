import { useMutation } from '@apollo/client';
import { Close } from '@mui/icons-material';
import {
  Grid,
  Slide,
  TableBody
} from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import toast from 'react-hot-toast';
import { OgaToken } from '../../graphql/token';
import { SEND_TRANSFER_RECEIPT_EMAIL_MUTATION } from '../../mutations/stockTransfer';
import { useStateValue } from '../../providers/stateProvider';
import { getUser, handleDownloadUtil } from '../../utils/utils';
import {
  BodyCell,
  ButtonDark,
  ButtonGray,
  ButtonOutlined,
  CDialog,
  CTableRow,
  CardTitle,
  DateText,
  DialogWrapper,
  Email,
  HeaderGrid,
  InnerText,
  LocationGrid,
  Printer,
  ReceiptFooter,
  Separator,
  TableHeader
} from './transferReceipt.styles';

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

const TransferReceipt = ({
  openDialog, closeDialog
}) => {
  const [{
    stockTransfer: { transferredData },
  },] = Object.values(useStateValue());

  const { transferLocation, transfers } = transferredData ?? {};
  const { name } = transferLocation ?? {};
  const {
    id, receiptNumber, dateCreated, stocktransferproductbatchSet, businessUser
  } = transfers ?? {};
  const { user } = businessUser ?? {};
  const sender = getUser(user);
  const date = dateCreated || '';
  const dateSent = new Date(date).toLocaleString('en-US', {
    weekday: 'short', day: '2-digit', month: 'short', year: 'numeric', hour: '2-digit', minute: '2-digit',
    hour12: true
  });


  const token = localStorage.getItem('oga_rest_token');

  const handlePrint = async () => {
    try {
      await handleDownloadUtil(OgaToken.SERVER_LINK, 'download_stock_transfer_invoice', token, `${id}.pdf`);
    } catch (err) {
      toast.error('Invoice download failed');
      console.error('download failed:', err);
    }
  };

  const [sendReceiptViaMail] = useMutation(SEND_TRANSFER_RECEIPT_EMAIL_MUTATION);
  const handleEmail = () => {
    sendReceiptViaMail({
      variables: {
        id
      }
    }).then(({ data }) => {
      const { message } = data?.sendStockTransferReceiptViaEmail || '';
      toast.success(`${message}`);
    }).catch((err) => {
      toast.error(`${err}`);
    });
  };

  return (
    <CDialog
      open={openDialog}
      TransitionComponent={Transition}
      keepMounted
      onClose={closeDialog}
      maxWidth="sm"
      fileslimit={1}
    >
      <DialogWrapper item container>
        <Grid item container justifyContent="space-between">
          <CardTitle>Stock Transfer Receipt</CardTitle>
          <Close
            fontSize="small"
            onClick={closeDialog}
            style={{ cursor: 'pointer' }}
          />
        </Grid>
        <HeaderGrid item container>
          <CardTitle style={{ color: '#fff' }}>
            {`Receipt #${receiptNumber}`}
          </CardTitle>
          <DateText variant="caption">{dateSent}</DateText>
        </HeaderGrid>
        <LocationGrid item container>
          <Grid item container xs={6}>
            <InnerText>Transfer Location</InnerText>
          </Grid>
          <Grid item container xs={6} justifyContent="center">
            <InnerText>{name}</InnerText>
          </Grid>
        </LocationGrid>
        <Grid item container>
          <Grid item container>
            <Grid item container>
              <TableHeader>S/N</TableHeader>
              <TableHeader>Product Name</TableHeader>
              <TableHeader>Batch #</TableHeader>
              <TableHeader>Qty Transferred</TableHeader>
            </Grid>
            <TableBody style={{ width: '100%' }}>
              {stocktransferproductbatchSet?.map((item, indx) => (
                <CTableRow item container>
                  <BodyCell>{indx + 1}</BodyCell>
                  <BodyCell>{item?.product?.brandName}</BodyCell>
                  <BodyCell>{item?.batch?.batchNo}</BodyCell>
                  <BodyCell>{item?.transferQty}</BodyCell>
                </CTableRow>
              ))}
            </TableBody>
          </Grid>
          <LocationGrid item container style={{ marginTop: '1rem' }}>
            <Grid item container xs={6}>
              <InnerText style={{ fontWeight: 500 }}>Handler today</InnerText>
            </Grid>
            <Grid item container xs={6} justifyContent="center">
              <InnerText>{sender}</InnerText>
            </Grid>
          </LocationGrid>
          <Separator />
          <ReceiptFooter item container>
            <ButtonOutlined disableElevation variant="outlined" onClick={closeDialog}>
              Close Transfer
            </ButtonOutlined>
            <ButtonGray disableElevation variant="contained" onClick={handleEmail}>
              <Email />
              Send To Email
            </ButtonGray>
            <ButtonDark variant="contained" onClick={handlePrint}>
              <Printer />
              Print Receipt
            </ButtonDark>
          </ReceiptFooter>
        </Grid>
      </DialogWrapper>
    </CDialog>
  );
};

TransferReceipt.propTypes = {
  openDialog: PropTypes.func.isRequired,
  closeDialog: PropTypes.func.isRequired,
};

TransferReceipt.defaultProps = {
};

export default TransferReceipt;
