import React from 'react';
import { styled } from '@mui/material/styles';
import { css } from '@emotion/react';

const shiftStyles = css`
  transition: ${(props) => props.theme.transitions.create('margin', {
    easing: props.theme.transitions.easing.easeOut,
    duration: props.theme.transitions.duration.enteringScreen,
  })};
  margin-left: 251px;
`;

const unshiftStyles = css`
  transition: ${(props) => props.theme.transitions.create('margin', {
    easing: props.theme.transitions.easing.sharp,
    duration: props.theme.transitions.duration.leavingScreen
  })};
  margin-left: 0;
`;

const contentShift = ({ open }) => {
  switch (open) {
    case true:
      return shiftStyles;
    default:
      return unshiftStyles;
  }
};

export const MainContainer = styled(({ open, theme, ...otherProps }) => (
  <main {...otherProps} />
))`
  flex-grow: 1;
  ${contentShift}
`;
