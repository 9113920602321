import { styled } from '@mui/material/styles';
import {
  TableCell, Checkbox, Typography, Grid, Paper, TableRow
} from '@mui/material';
import { StTransfer, ExportIcon, CartIcon } from '../../assets/svgs';
import CustomButton from '../customComponents/customButton';

export const PaperWrapper = styled(Paper)`
  border-radius: 10px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
`;

export const ExportIconStyled = styled(ExportIcon)`
  font-size: 1.2rem;
  color: '#606060';
  margin-right: 5px;
`;

export const CartIconStyled = styled(CartIcon)`
  font-size: 36px;
  padding-top: 7px;
`;

export const CartText = styled('span')`
  font-size: 12px;
  margin-left: -3px;
  font-weight: 500;
  color: #FFFFFF;
`;

export const ExportText = styled('span')`
  font-size: 13px;
  font-weight: 400;
  color: #16181B;
`;

export const SearchGrid = styled(Grid)`
   padding: 2rem 0.8rem 0 1.6rem;
   align-items: center;
`;

export const TransferIcon = styled(StTransfer)`
  font-size: 1.2rem;
  color: '#606060';
  margin-right: 5px;
`;

export const TableGrid = styled(Grid)`
  margin:  1.5rem;
  overflow-x: auto;
`;

export const MainTableHead = styled(TableRow)`
`;

export const TableHeader = styled(TableCell)`
  /**text-transform: capitalize;**/
  font-size: 1rem;
  font-weight: 600;
  line-height: 2.8;
  padding: 10px;
  border-bottom: 0;
  background: #F3F9FF;
`;

export const TCustomButton = styled(CustomButton)`
  border: 1px solid #000000;
  box-shadow: 0px 4px 14px rgba(151, 151, 151, 0.19);
  border-radius: 5px;
  height: 3rem;
`;

export const TSCustomButton = styled(CustomButton)`
  border: 1px solid #E7E8E9;
  box-shadow: 0px 4px 14px rgba(151, 151, 151, 0.19);
  border-radius: 5px;
  height: 3rem;
  color: #858585;
`;

export const TitleGrid = styled(Grid)`
  justify-content: space-between;
  margin-bottom: 2rem;
  align-items: center;
`;

export const TitleTextGridContainer = styled(Grid)`
  align-items: center;
  display: flex;
`;

export const PrimaryTitle = styled(Typography)`
  font-size: 2.2rem;
  font-weight: 700;
  color: #303030;

  @media(min-width: 992px) {
    font-size: 24px;
  }
`;

export const CustomCheckbox = styled(Checkbox)`
  color: #424242;
  & .MuiSvgIcon-fontSizeSmall {
    font-size: 1.4rem;
    fill: #235A91;
  }
`;

export const FooterWrapper = styled(Grid)`
  width: 100%;
  padding: 0 1.5rem 1.5rem;
  margin: 2rem 0;
`;
