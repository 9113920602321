import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {
  Grid, Hidden, Typography
} from '@mui/material';
import { Mutation } from '@apollo/client/react/components';
import toast from 'react-hot-toast';

import { StaticRouter, useLocation, useNavigate } from 'react-router-dom';
import { RETURN_ORDER, COMPLETE_RETURN_ORDER } from '../../../mutations/orders';

import { parseJson } from '../../affiliates/utils';
import ReturningProductItemOverview from './returningProductItemOverview';

import MainContent from '../../customComponents/mainContent';
import ReturnSelectField from '../../suppliers/individual/returnSelectField';
import {
  BackArrowIconContainer, PageTitleText, PageTitleSubText, DeliveryAddressCardHeader,
  DeliveryAddressCardHeaderTitle, DeliveryAddressCardHeaderSubTitle, PageTitleContainer,
  KeyboardArrowLeft, ChangeOutletRow, SelectOutletText, OutletInfoContainer, OutletContactLine, ProductColumnHeaderTitle,
  SupplierCardPaper, SupplierProductsCardFooterText, SupplierProductsCardHeader, SupplierProductsCardHeaderText
} from '../../cart/cart.styles';

import {
  ReturnOrderButton, ReturnOrderButtonText, CancelReturnButton, CartGridContainer, CartGridContainerHeader,
  ReturnSelectedOrderButtonText, ReturnSelectedIcon, CancelReturnButtonText, ReturnSelectedOrderButton,
  ReturnProductFooterGrid, AddDeliveryButton, AddDeliveryButtonText, DeliveryAddressCard, ButtonBox
} from './returns.styles';
import { OgaToken } from '../../../graphql/token';
import {handleDownloadUtil } from '../../../utils/utils'
import { SupplierDetailCardWrapper } from '../supplierorder-detail/styles';

import { DeliveryAddressCardSection } from '../dispatch/dispatchContainer.styles';

import GoToTop from '../../customComponents/scrollToTop';
import Returns from './returns';
import AddLocationDialog from './addLocationDialog/addLocationDialog';
import OrderInfoCard from '../../shared/orderInfoCard/orderInfoCard';
import CancelReturnDialog from './cancelReturnDialog/cancelReturnDialog';
import SuccessDialog from '../../rolesAndPermissions/users/successDialog';

const OrderReturnReview = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const initialState = location?.state;

  const [state, setState] = useState(initialState);
  const [openDialog, setOpenDialog] = useState(false);
  const [returnDialog, setReturnDialog] = useState(false);
  const [successDialog, setSuccessDialog] = useState(false);

  const handleCloseDialog = () => {
    setOpenDialog(!openDialog);
  };

  const handleReturnDialog = () => {
    setReturnDialog(!returnDialog);
  };

  const handleSuccessDialog = () => {
    setSuccessDialog(!successDialog);
    navigate('/orders-admin');
  };

  const handleBack = () => {
    navigate('/return-order', {
      state: { ...state, editReturn: true }
    });
  };

  const token = localStorage.getItem('oga_rest_token');

  const downloadWaybill = async () => {
    try {
      const { id } = state;
      await handleDownloadUtil(OgaToken.SERVER_LINK, 'download-waybill-invoice', token, `${id}.pdf`);
    } catch (err) {
      toast.error('Invoice download failed');
      console.error('Invoice download failed:', err);
    }
  };

  const handleChangeReason = (id, value, key) => {
    const { cart } = state;
    const existingCartItem = cart.find((item) => item.id === id);
    if (!existingCartItem.recieved && key !== 'recieved') return;
    setState({
      ...state,
      cart: cart.map((cartItem) => (cartItem.id === existingCartItem.id
        ? { ...cartItem, [key]: value }
        : cartItem))
    });
  };

  const handleIncrementProductQuantityInCart = (id) => {
    const { cart } = state;

    const existingCartItem = cart.find((item) => item.id === id);
    if (!existingCartItem.recieved) return;

    if (existingCartItem.quantityReturned < existingCartItem.quantityRecieved) {
      setState({
        ...state,
        cart: cart.map((cartItem) => (cartItem.id === existingCartItem.id
          ? { ...cartItem, quantityReturned: cartItem.quantityReturned + 1 }
          : cartItem))
      });
    }
  };

  const handleChangeProductQuantityInputValue = (id, value) => {
    const { cart } = state;
    let newValue = 0;
    if (Number(value)) newValue = value;
    else newValue = 1;

    const existingCartItem = cart.find((item) => item.id === id);
    if (!existingCartItem.recieved) return;
    setState({
      ...state,
      cart: cart.map((cartItem) => (cartItem.id === existingCartItem.id
        ? { ...cartItem, quantityReturned: newValue }
        : cartItem))
    });
  };

  const handleDecrementProductQuantityInCart = (id) => {
    const { cart } = state;
    const existingCartItem = cart.find(
      (cartItem) => cartItem.id === id
    );
    if (!existingCartItem.recieved) return;

    if (existingCartItem.quantityRecieved >= 1 && existingCartItem.quantityReturned > 0) {
      setState({
        ...state,
        cart: cart.map((cartItem) => (cartItem.id === existingCartItem.id
          ? { ...cartItem, quantityReturned: cartItem.quantityReturned - 1 }
          : cartItem))
      });
    }
  };

  const [changeAlarm, setChangeAlarm] = useState(false);
  const handleDeliveryChange = (event) => {
    state.selectedDeliveryLocation = event.target.value;
    setChangeAlarm(!changeAlarm);
  };

  const getDeliveryLocationOption = () => {
    const optionsBox = { name: 'deliverTo', label: 'Deliver To', options: [] };
    const { deliveryLocations } = state;
    deliveryLocations.forEach((deliveryLocation) => {
      let { contacts } = deliveryLocation;
      try {
        contacts = parseJson(contacts.replace(/'/g, '"'));
      } catch (e) {
        contacts = JSON.stringify(contacts);
        contacts = parseJson(contacts.replace(/'/g, '"'));
      }
      optionsBox.options.push(`${contacts.address_line_1}, ${contacts.city}, ${contacts.country}`);
    });
    return optionsBox;
  };

  const getSelectedDeliveryLocationInfo = () => {
    const { selectedDeliveryLocation, deliveryLocations } = state;
    const delivery = deliveryLocations.find((deliveryLocation) => {
      let { contacts } = deliveryLocation;
      try {
        contacts = parseJson(contacts.replace(/'/g, '"'));
      } catch (e) {
        contacts = JSON.stringify(contacts);
        contacts = parseJson(contacts.replace(/'/g, '"'));
      }
      const contactAdress = `${contacts.address_line_1}, ${contacts.city}, ${contacts.country}`;
      return contactAdress === selectedDeliveryLocation || deliveryLocation.name === selectedDeliveryLocation;
    });
    let { contacts } = delivery;
    try {
      contacts = parseJson(contacts.replace(/'/g, '"'));
    } catch (e) {
      contacts = JSON.stringify(contacts);
      contacts = parseJson(contacts.replace(/'/g, '"'));
    }
    return `${contacts.address_line_1}, ${contacts.city}, ${contacts.country}`;
  };

  useEffect(() => {
    getDeliveryLocationOption();
    getSelectedDeliveryLocationInfo();
  }, [changeAlarm]);

  const renderSelectOutletInfo = () => {
    const selectedDelivryLocationInfo = getSelectedDeliveryLocationInfo();
    if (selectedDelivryLocationInfo) {
      return (
        <OutletInfoContainer>
          <OutletContactLine><b>Delivery Address</b></OutletContactLine>
          <br />
          <OutletContactLine>
            {selectedDelivryLocationInfo}
          </OutletContactLine>
        </OutletInfoContainer>
      );
    }
    return null;
  };

  const renderSupplierContainers = () => {
    const { cart } = state;

    const supplierCategories = _.groupBy(cart, 'supplier');

    return Object.entries(supplierCategories).map(([key, value]) => (
      <SupplierCardPaper elevation={2}>
        <SupplierProductsCardHeader>
          <SupplierProductsCardHeaderText>
            Supplier:
            &nbsp;
            {key.split(' ')[0]}
          </SupplierProductsCardHeaderText>
        </SupplierProductsCardHeader>
        {value.filter((p) => p.quantityRecieved > 0).map((productItem) => (
          <ReturningProductItemOverview
            product={productItem}
            selectedOulet={state.selectedOutlet}
          />
        ))}
        <ReturnProductFooterGrid container>
          <SupplierProductsCardFooterText>
            {`${value.length} ${value.length > 1 ? 'Items' : 'Item'}`}
          </SupplierProductsCardFooterText>
        </ReturnProductFooterGrid>
      </SupplierCardPaper>
    ));
  };

  const handleChangeDeliveryLocation = (newAddress) => {
    state.deliveryLocations = newAddress;
    setChangeAlarm(!changeAlarm);
  };

  const handleSubmitReturn = (completeReturnOrder) => {
    const products = state.cart.map((productItem) => ({
      productId: productItem.id,
      returningQuantity: productItem.quantityReturned,
      reason: productItem.reason,
      batches: productItem.batches
    }));
    if (!products.length) return toast.error('Sorry, cannot return an empty product');
    completeReturnOrder({
      variables: {
        returnOrderId: state.id,
        products,
      }
    }).then((response) => {
      // const message = response?.data?.returnOrder.message;
      setSuccessDialog(true);
      // toast.success(message);
    }).then(() => {
      const timer = setTimeout(() => {
        navigate('/orders-admin');
      }, 10000);
      return () => clearTimeout(timer);
    }).catch((err) => {
      toast.error(err?.message);
    });
  };

  const returnDiscreption = () => (
    <Typography>
      Hi User, your order has been successfully
      <br />
      returned.
    </Typography>
  );

  return (
    <Mutation mutation={COMPLETE_RETURN_ORDER}>
      {(completeReturnOrder) => (state ? (
        <MainContent>
          <CartGridContainer container>
            <BackArrowIconContainer
              style={{ marginRight: 'auto' }}
              onClick={handleBack}
            >
              <KeyboardArrowLeft />
              <Typography>back</Typography>
            </BackArrowIconContainer>
            <CartGridContainerHeader container>
              <Grid item md={5}>
                <PageTitleContainer>
                  <PageTitleText>Close Order Return</PageTitleText>
                  <PageTitleSubText>Specify portion of order to return</PageTitleSubText>
                </PageTitleContainer>
              </Grid>
              <Grid item md={7} style={{ paddingBottom: '1.5rem' }}>
                <ButtonBox>
                  <CancelReturnButton>
                    <CancelReturnButtonText onClick={downloadWaybill}>Generate Waybill</CancelReturnButtonText>
                  </CancelReturnButton>
                  <CancelReturnButton onClick={() => setReturnDialog(true)}>
                    <CancelReturnButtonText>Cancel Return</CancelReturnButtonText>
                  </CancelReturnButton>
                  <CancelReturnButton onClick={handleBack}>
                    <CancelReturnButtonText>Edit Return</CancelReturnButtonText>
                  </CancelReturnButton>
                  <ReturnSelectedOrderButton
                    onClick={() => handleSubmitReturn(completeReturnOrder)}
                  >
                    <ReturnSelectedOrderButtonText>
                      Complete Return
                    </ReturnSelectedOrderButtonText>
                  </ReturnSelectedOrderButton>
                </ButtonBox>
              </Grid>
            </CartGridContainerHeader>
            <Grid
              container
              justifyContent="space-between"
              spacing={3}
            >
              <SupplierDetailCardWrapper item container lg={6}>
                <OrderInfoCard
                  id={String(state.id).padStart(6, 0)}
                  business={state}
                  dateCreated={state.businessDateCreated}
                  businessDateDelivered={state.businessDateDelivered}
                  fulfilledBy={localStorage.getItem('oga_username')}
                  returns
                />
              </SupplierDetailCardWrapper>
              <DeliveryAddressCard item md={12} lg={6}>
                <DeliveryAddressCardSection elevation={2}>
                  <DeliveryAddressCardHeader>
                    <DeliveryAddressCardHeaderTitle>Delivery Location</DeliveryAddressCardHeaderTitle>
                    <AddDeliveryButton onClick={() => setOpenDialog(true)}>
                      <AddDeliveryButtonText>Add Delivery Location</AddDeliveryButtonText>
                    </AddDeliveryButton>
                    {/* <DeliveryAddressCardHeaderSubTitle
                      type="button"
                    >
                      Add Outlet
                    </DeliveryAddressCardHeaderSubTitle> */}
                  </DeliveryAddressCardHeader>
                  <Grid style={{ marginBottom: '20px' }}>
                    <SelectOutletText>
                      Select the outlet you want your order to be
                      <br />
                      delivered to from the dropdown below
                    </SelectOutletText>
                    <ChangeOutletRow>
                      <ReturnSelectField
                        field={getDeliveryLocationOption()}
                        value={getSelectedDeliveryLocationInfo()}
                        handleChange={handleDeliveryChange}
                        showCheckBox={false}
                        label=""
                        fullWidth
                        returns
                      />
                    </ChangeOutletRow>
                  </Grid>
                  {renderSelectOutletInfo()}
                </DeliveryAddressCardSection>
              </DeliveryAddressCard>
            </Grid>
            <Returns
              state={state}
              handleChangeReason={handleChangeReason}
              handleIncrement={handleIncrementProductQuantityInCart}
              handleDecrement={handleDecrementProductQuantityInCart}
              handleChangeProductQuantity={handleChangeProductQuantityInputValue}
              disableField
              reviewOrder
            />
            <AddLocationDialog
              open={openDialog}
              close={handleCloseDialog}
              currentState={state}
              changeDelivery={(newAddress) => handleChangeDeliveryLocation(newAddress)}
            />
            <CancelReturnDialog
              open={returnDialog}
              close={handleReturnDialog}
              reviewPage
              orderId={state.id}
            />
            <SuccessDialog
              openConfirmation={successDialog}
              handleClose={handleSuccessDialog}
              title="Order Returned Successfully!"
              discreption={returnDiscreption()}
            />
            {/* <Hidden mdDown>
              <ReturningProductColumnHeaders>
                <ProductColumnHeaderTitle style={{ width: '490px' }}>
                  Product Item
                </ProductColumnHeaderTitle>
                <ProductColumnHeaderTitle style={{ width: '304px' }}>
                  Qty Delivered
                </ProductColumnHeaderTitle>
                <ProductColumnHeaderTitle style={{ width: '304px' }}>
                  Qty Returned
                </ProductColumnHeaderTitle>
                <ProductColumnHeaderTitle style={{ width: '165px' }}>
                  Reason
                </ProductColumnHeaderTitle>
              </ReturningProductColumnHeaders>
            </Hidden> */}
            {/* {renderSupplierContainers()} */}
            {/* <GoToTop /> */}
          </CartGridContainer>
        </MainContent>
      ) : '')}
    </Mutation>
  );
};

// OrderReturnReview.propTypes = {
//   location: PropTypes.instanceOf(Object)
// };

// OrderReturnReview.defaultProps = {
//   location: {}
// };

export default OrderReturnReview;
