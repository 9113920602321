import {
  TableCell, Checkbox, Grid, TableRow, Box, Typography
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { css } from '@emotion/react';

export const MainTableRow = styled(TableRow)`
  width: 100%;
  align-items: center;

  & > * {
    border-bottom: unset;
  }
  :nth-child(2n) {
    background: #F3F9FF;
  }
`;

export const BodyCell = styled(TableCell)`
  text-transform: capitalize;
  font-size: 1.2rem;
  line-height: 2.8;
  padding: 10px;
  font-size: .9rem;
`;

const upcoming = css`
  background-color: #F29053;
  // color: #606060;
`;

const ongoing = css`
  background-color: #00B588;
  // color: #07DE10;
`;

const ended = css`
  background-color: #FF0F0F;
  // color: #FA3636;
`;

const getStyles = ({ status }) => {
  switch (status) {
    case 'upcoming':
      return upcoming;
    case 'ongoing':
      return ongoing;
    case 'ended':
      return ended;
    default:
      return ended;
  }
};

export const ColorGrid = styled(Grid)`
  border-radius: 7px;
  text-transform: capitalize;
  font-size: 16px;
  line-height: 2.8;
  font-weight: 500;
  justify-content: center;
  width: 60%;

  ${getStyles}
`;

export const StatusColor = styled(Box)`
  width: 0.563rem;
  height: 0.563rem;
  border-radius: 50%;
  margin-top: 7px;
  ${getStyles}
`;

export const StatusText = styled(Typography)`
  margin-left: 10px;
`;
