import { TableCell } from '@mui/material';
import { styled } from '@mui/material/styles';
import { css } from '@emotion/react';

export const TableColumnRows = styled('div')`
  display: flex;

  :nth-child(even) {
    background: #F4F5F7;
  }
`;

export const TableColumnRowText = styled(TableCell)`
  font-size: 1.2rem;
  font-weight: 400;
  line-height: 26.27px;
  color: #606060;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 16px 10px;
  border: 0;

  @media(min-width: 992px) {
    font-size: 16px;
  }
`;

const SoldOutStyles = css`
  background-color: #FBF777;
  color: #606060;
`;

const activeStyles = css`
  background-color: #DCFEDD;
  color: #07DE10;
`;

const inactiveStyles = css`
  background-color: #FBDADA;
  color: #FA3636;
`;

const getStyles = ({ status }) => {
  switch (status) {
    case 'active':
      return activeStyles;
    case 'inactive':
      return inactiveStyles;
    default:
      return SoldOutStyles;
  }
};
