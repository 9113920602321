import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import {
  PopperContainer, WrapperPaper, HeaderText, TitleText, SubtitleText,
  PopperGrid
} from './promoDetailsPopper.styles';

const PromoDetailsPopper = ({
  countdown, savings, savingsPercent
}) => (
  <PopperContainer className="promo-details-popper">
    <WrapperPaper>
      <HeaderText>More Details</HeaderText>
      <PopperGrid container spacing={2}>
        <Grid item xs={6}>
          <TitleText>Promo Time:</TitleText>
        </Grid>
        <Grid item xs={6}>
          <SubtitleText>{countdown || 'NIL'}</SubtitleText>
        </Grid>
      </PopperGrid>
      <PopperGrid container spacing={2}>
        <Grid item xs={6}>
          <TitleText>Savings:</TitleText>
        </Grid>
        <Grid item xs={6}>
          <SubtitleText>{savings || 0}</SubtitleText>
        </Grid>
      </PopperGrid>
      <PopperGrid container spacing={2}>
        <Grid item xs={6}>
          <TitleText>%Savings:</TitleText>
        </Grid>
        <Grid item xs={6}>
          <SubtitleText>
            {savingsPercent}
            %
          </SubtitleText>
        </Grid>
      </PopperGrid>
    </WrapperPaper>
  </PopperContainer>
);

PromoDetailsPopper.propTypes = {
  countdown: PropTypes.string,
  savings: PropTypes.string,
  savingsPercent: PropTypes.string
};

PromoDetailsPopper.defaultProps = {
  countdown: '',
  savings: '',
  savingsPercent: ''
};

export default PromoDetailsPopper;
