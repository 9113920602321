import React from 'react';
import PropTypes from 'prop-types';
import { useMediaQuery } from '@mui/material';
import currencyFormatter from '../../shared/currencyFormatter';
import { CustomCheckbox, MainTableRow, BodyCell } from './returnRow.styles';
import { Product } from '../../../providers/reducers/product/classes/Product';

const ReturnRow = ({
  row, selected, handleSelect
}) => {
  const isSelected = () => selected.indexOf(row?.id) !== -1;
  const {
    brandName, packSize, orderCost, resolvedPriceInUseValue, promoPrice
  } = row;
  const isLarge = useMediaQuery('(min-width:992px)');

  const price = `₦ ${currencyFormatter(resolvedPriceInUseValue)}`;
  const cost = `₦ ${currencyFormatter(orderCost)}`;
  const promo = `₦ ${currencyFormatter(promoPrice)}`;

  const cells = [brandName, packSize, cost, price, promo];

  const renderCheckbox = () => (
    <CustomCheckbox
      size="small"
      checked={isSelected()}
      onChange={(e) => {
        e.stopPropagation();
        handleSelect(e, row.id);
      }}
      inputProps={{ 'aria-label': 'select product' }}
    />
  );

  const returnCell = (value) => value || '-';

  const renderBodyCells = () => {
    return cells.map((cell) => (
      <BodyCell>{isLarge ? returnCell(cell) : ''}</BodyCell>
    ));
  };

  return (
    <MainTableRow item container>
      <BodyCell>{renderCheckbox()}</BodyCell>
      {renderBodyCells()}
    </MainTableRow>
  );
};

ReturnRow.propTypes = {
  row: PropTypes.instanceOf(Product),
  selected: PropTypes.instanceOf(Array),
  handleSelect: PropTypes.func.isRequired,
};

ReturnRow.defaultProps = {
  row: {},
  selected: []
};

export default ReturnRow;
