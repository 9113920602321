export const addItemToCart = (cartItems, payload) => {
  const { product, counter } = payload;
  const existingCartItem = cartItems.find(
    (cartItem) => cartItem.id === product.id
  );

  if (existingCartItem) {
    return cartItems.map((cartItem) => (cartItem.id === existingCartItem.id
      ? { ...cartItem, quantity: cartItem.quantity + counter }
      : cartItem));
  }

  return [...cartItems, { ...product, quantity: counter }];
};

export const updateCartCounter = (cartTot) => cartTot;

export const updateCartCount = (total) => total;
