import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useMutation, useQuery, useSubscription } from '@apollo/client';
import { useNavigate, useLocation } from 'react-router-dom';
import {
  Grid, Dialog, DialogActions, DialogContent, DialogTitle, Hidden,
  Slide, Checkbox, TableRow, TableBody, TableCell
} from '@mui/material';
import { toast } from 'react-hot-toast';
import HighlightOffRoundedIcon from '@mui/icons-material/HighlightOffRounded';
import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded';
import _ from 'lodash';
import currencyFormatter from '../shared/currencyFormatter';
import MainContent from '../customComponents/mainContent';
import {
  CartGridContainer, DialogActionButtonText, ProductSubTotalHeaders, ProductColumnHeaderTitle, BackArrowIconContainer,
  PageTitleText, CartItemCountContainer, CartItemCountText, DeliveryAddressCard, DeliveryAddressCard2, CustomToast,
  DeliveryAddressCardSection, DeliveryAddressCardHeader, DeliveryAddressCardHeaderTitle, DeliveryAddressCardHeaderSubTitle,
  SupplierCardPaper, CartGridContainerHeader, DialogCancelButton, DialogOkButton, KeyboardArrowLeft, ChangeOutletRow,
  SelectOutletText, OutletInfoContainer, OutletContactLine, ManualMethodImage, ManualDialogDoneButton, EmptyCartImg,
  ManualDialogCancelButton, ManualDialogPadder, ManualPlaceOrder, OptionText, ManualSupplierItem, Span,
  CircularProgressLoader, LoaderGrid, ArrowBackText, ClearBox, ClearAllCart, TitleText, ContentText, CartImage,
  CartItemWrapper, CartContainer, MainTableRow, Header, TableHeader, OutletContactHeader, ViewMore, GridContainer,
  GridWrapper, ItemWrapper, ItemTypo, EditContainer, TableWrapper, ProductSubTotalWrapper, EmptyCardPaper, TextWrapper,
  TextHeader, CartItemContainer, PaymentCardSection, TextBody, IconWrapper, DeliveryIcon, Default, SearchGrid, SupplierCard, FooterGrid,
  BannerImage, BannerBox, CartHeader
} from './cart.styles';
import ProductItem from './product-item/index';
import CreateOutletModal from '../shared/addSingleOutlet/individualOutletDialog';
import {
  PLACE_ORDER_PRODUCTS_MUTATION, DELETE_CART_ORDER,
  PLACE_AFFILIATE_ORDER_PRODUCTS_MUTATION, SWITCH_ORDER_PRODUCT_SUPPLIER, DELETE_CART_PRODUCT
} from '../../mutations/cart';
import { ORDER_QUERY, SAVED_CART, MP_CART_ORDER_QUERY } from '../../queries/cart';
import { ORDER_UPDATED_SUBSCRIPTION, ORDER_UPLOADED_SUBSCRIPTION } from '../../subscriptions/orders';
import PaymentSummary from './paymentSummaryCard/PaymentSummary';
import ReturnSelectField from '../suppliers/individual/returnSelectField';
import Print from '../../assets/images/Print.png';
import Pdf from '../../assets/images/select.png';
import Email from '../../assets/images/Email.png';
import EmptyCart from '../../assets/images/empty_cart.gif';
import SupplierOrderForm from '../../assets/images/manual_supplier_order.png';
import Cart from '../../assets/images/CartBlue.png';
import { SupplierOrderPrint } from './supplierOrderPrint';
import {
  TableContainerWrapper, TableComponent, HeaderCell,
  MainTableHead, AddProductCardSection, AddProductCard, CartItemButton, CartItemButtonContainer
} from './newProduct.styles';
import { Delete, ShopNowButton } from './product-item/styles';
import Floater from './floater';
import { Product } from '../../providers/reducers/product/classes/Product';
import { DELETE_DELIVERY_LOCATION } from '../../mutations/outlets';
import { OgaToken } from '../../graphql/token';
import { RenderPopper, handleDownloadUtil } from '../../utils/utils';
import ConfirmDialog from './confirmDialog';
import SimilarProducts from './similarProducts/similarProduct';
import ClearCartDialog from './clearCartDialog';
import { Dots, CartDot } from '../../assets/svgs';
import ActionPopper from './actionPopper';
import SaveDialog from './saveCart';
import CartSaved from './CartSaved';
import SavedCartDialog from './SavedCart';
import NoSavedCart from './NoSavedCart';
import { useStateValue } from '../../providers/stateProvider';
import { parseJson, parseProfileJson } from '../affiliates/utils';
import CartActionTypes from '../../providers/reducers/cart/cartTypes';
import CustomSearchField from '../shared/CustomSearchField';
import TablePagination from '../shared/tablePagination';
import { UPDATE_ORDER_MUTATION } from '../../mutations/orders';
import { reformatAddress } from '../../utils/json';
import CartProcessingModal from './cartProcessingModal';
import { TabSessionIdempotentKey, clearLocalStorageIdempotentEntries } from '../../global';
import AddressConfirmDialog from '../shared/confirmDialog';
import { GET_LINKED_POS } from '../../queries/affiliates';
import SuccessDialog from '../shared/successDialog';
import { BANNERS_PAGE_QUERY } from '../../queries/banners';

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

const dosages = [
  { name: 'Tablet' }, { name: 'Capsule' }, { name: 'Caplet' }, { name: 'Syrup' }, { name: 'Suspension' }, { name: 'Solution' },
  { name: 'Gel' }, { name: 'Injection' }, { name: 'Infusion' }, { name: 'Suppository' }, { name: 'Pessary' }, { name: 'Cream' },
  { name: 'Ointment' }, { name: 'Lotion' }, { name: 'Paste' }, { name: 'Powder' }, { name: 'Other' }
];

const generateExtraProduct = () => ({
  brandName: '', dosageForm: 'Select', strength: '', packSize: '', quantity: 0
});
const initialState = {
  orderId: null,
  cart: [],
  dataCartLoading: true,
  existingOutlet: null,
  wantExtraProducts: false,
  extraProducts: [generateExtraProduct(), generateExtraProduct(), generateExtraProduct(), generateExtraProduct()],
  acceptedExtraProducts: [],
  toBePrintedManualSupplierOrderForms: new Map(),
  extraPlaceOrderActions: new Map(),
  printSupplierOrder: false,
  serviceFee: 0,
  totalPromo: 0,
  pricePolicyFactor: 1,
  deliveryFee: 0,
  updateLoading: false,
  placeLoading: false,
  showDeleteDialog: false,
  showPlaceOrderDialog: false,
  toBeDeletedItem: null,
  deliveryAddress: '',
  changeLocation: false,
  deliveryLocations: [],
  selectedDeliveryLocation: '',
  addNewOutlet: false,
  addressLine1: '',
  addressLine2: '',
  city: '',
  country: 'Nigeria',
  placeManualOrder: false,
  openMCF: false,
  openPrintDialog: false,
  openExportModel: false,
  businessUserId: 0,
  viewMoreButton: null,
  deleteAddressDialog: false,
  clearCartDialog: false,
  actionPopper: null,
  saveDialog: false,
  cartSaved: false,
  savedCartDialog: false,
  cartOrder: [],
  noSavedCart: false,
  searchText: '',
  cartOrderProductsCount: 0,
  totalPaymentSummary: 0,
  editOutlet: false,
  orderProcessingDialog: false,
  newOrderId: '',
  ordering: false
};

const CartIndex = ({ manualCart }) => {
  const localBusinessId = localStorage.getItem('ogarx_business_id');
  const [state, setState] = useState(initialState);
  const [searchValue, setSearchValue] = useState('');
  const [pageCount, setPageCount] = useState(20);
  const [pageNumber, setPageNumber] = useState(1);
  const [searchText, setSearchText] = useState('');
  const [openOutletModal, setOpenOutletModal] = useState(false);
  const [existingOutlet, setExistingOutlet] = useState(null);
  const [modalMessage, setModalMessage] = useState({});
  const [addrsConfirmDialog, setAddrsConfirmDialog] = useState(false);
  const [successDialog, setSuccessDialog] = useState(false);

  const { data: linkedPosData } = useQuery(GET_LINKED_POS, {
    fetchPolicy: 'cache-and-network',
    variables: { mpBusinessId: localBusinessId },
  });

  const { linkedPosAccountsTotal } = linkedPosData ?? {};

  const [updateOrder] = useMutation(UPDATE_ORDER_MUTATION);

  const [{
    business: { subBusinessesIds },
    user: {
      businessCanUseMastersList, isBusinessVirtualStore, priceFactor, platform
    },
    cart: { cartStatus }
  }, dispatch] = Object.values(useStateValue());

  const canUseMastersList = businessCanUseMastersList || isBusinessVirtualStore;

  const { alertMessage, alertSeverity } = useLocation();
  const navigate = useNavigate();
  const backRoute = -1;

  if (alertMessage && alertSeverity === 'success') {
    toast.success(alertMessage);
  } else if (alertMessage && alertSeverity === 'error') {
    toast.error(alertMessage);
  }

  const {
    cart, serviceFee, deliveryFee, placeLoading, updateLoading, showDeleteDialog, showPlaceOrderDialog, ordering,
    selectedDeliveryLocation, placeManualOrder, toBePrintedManualSupplierOrderForms, orderId, newOrderId,
    printSupplierOrder, extraPlaceOrderActions, extraProducts, wantExtraProducts, showLoan, business, viewMoreButton, deleteAddressDialog, clearCartDialog, actionPopper,
    saveDialog, cartSaved, savedCartDialog, cartOrder, noSavedCart, cartProductsCount, totalPaymentSummary, orderProcessingDialog
  } = state;

  const [switchOrderProductSupplier] = useMutation(SWITCH_ORDER_PRODUCT_SUPPLIER);
  const [deleteCart] = useMutation(DELETE_CART_ORDER);
  const [deliveryLocation] = useMutation(DELETE_DELIVERY_LOCATION);
  const [placeAffiliateOrderProducts] = useMutation(PLACE_AFFILIATE_ORDER_PRODUCTS_MUTATION);
  const [placeOrderProducts] = useMutation(PLACE_ORDER_PRODUCTS_MUTATION);
  const [deleteCartProduct] = useMutation(DELETE_CART_PRODUCT);

  const retrieveProducts = (orderSet) => {
    let products = orderSet;

    products = products.map((p) => {
      const {
        name,
        quantity,
        id: orderProductId,
        hybrid,
        alternativeSupplier: { id: alternativeSupplierId, name: alternativeSupplierName },
        supplierOrder: { supplier: { ame: supplierName, settings } },
        product: { id, meta, business: { id: productBusinessId } },
        unitCost,
        meta: orderMeta,
        availableQty,
        orderProductStatus
      } = p;

      const {
        clearance_sale: clearanceSale, unit_cost_paid: untiCostPaid, batch_id: batchId
      } = parseJson(orderMeta?.replace(/False/g, "'false'").replace(/True/g, "'true'"));

      const supplierSettings = JSON.parse(settings.replace(/'/g, '"').replace('None', '"N/A"'));
      return {
        id,
        orderProductId,
        name,
        quantity,
        hybrid,
        clearanceSale,
        availableQty,
        orderProductStatus,
        alternativeSupplierId,
        alternativeSupplierName,
        manualCart,
        ...new Product(p.product, manualCart && supplierName !== 'OGApharmacy' ? 'erp' : 'marketplace'),
        supplier: supplierName,
        supplierPaymentTerm: supplierSettings.payment_term,
        meta: JSON.parse(meta),
        productBusinessId,
        unitCost: clearanceSale ? untiCostPaid : unitCost,
        batchId,
        quantityInStock: availableQty
      };
    });

    setState((s) => ({ ...s, cart: products }));
  };

  const adminOrderingForBusiness = localStorage.getItem('oga_ordering_for_affiliate');
  const bizId = localStorage.getItem('userId');
  let businessId = 0;
  if (adminOrderingForBusiness) {
    businessId = JSON.parse(adminOrderingForBusiness).id;
  } else {
    businessId = bizId;
  }
  const variables = {
    bizId: +businessId,
    search: searchValue,
    pageCount,
    pageNumber
  };
  if (manualCart) {
    variables.isManualOrder = true;
  }

  const OrderQuery = platform === 'erp' ? ORDER_QUERY : MP_CART_ORDER_QUERY;

  const { data: orderData, loading, refetch } = useQuery(OrderQuery, {
    variables,
    fetchPolicy: 'no-cache',
  });

  const { data: savedCartData, loading: savedCartLoading } = useQuery(SAVED_CART, {
    fetchPolicy: 'no-cache',
  });

  const {
    data: bannerData
  } = useQuery(BANNERS_PAGE_QUERY, {
    fetchPolicy: 'cache-and-network',
  });

  const { bannerPages = [] } = bannerData || {};
  const banners = bannerPages?.filter((item) => item.page === 'Cart Page');
  let parsedMeta = [];

  if (banners && banners.length > 0) {
    parsedMeta = banners.flatMap((item) => item.meta.map((metaItem) => JSON.parse(metaItem)));
  }
  const { url: bannerUrl, banner: bannerImage } = parsedMeta[0] || {};

  const handleOrderProcessingDialog = () => {
    setState((s) => ({
      ...s, orderProcessingDialog: !s.orderProcessingDialog
    }));
  };

  const handleFinalOrderProcess = (subscriptionData) => {
    const { data, error } = subscriptionData ?? {};
    const { placeOrderStatus } = data?.orderUpdated ?? {};
    setState((s) => ({ ...s, orderProcessingDialog: false, ordering: false }));

    if (placeOrderStatus === 'COMPLETED') {
      dispatch({ type: CartActionTypes.CART_STATUS, payload: '' });
      dispatch({ type: CartActionTypes.CART_COUNT, payload: 0 });
      const businessRaw = localStorage.getItem('oga_ordering_for_affiliate');
      const _business = businessRaw ? JSON.parse(businessRaw) : null;
      const redirect = _business ? '/orders-admin' : '/orders';
      const url = platform === 'erp' ? '/inventory/orders' : redirect;
      if (_business) {
        localStorage.removeItem('oga_ordering_for_affiliate');
      }
      // toast.success('Order has been successfully sent');
      setTimeout(() => {
        navigate(`${url}`);
      }, 1500);
    } else if (error) {
      dispatch({ type: CartActionTypes.CART_STATUS, payload: 'order_error' });
      toast.error(error);
    } else if (placeOrderStatus === 'FAILED') {
      toast.error('Quantity ordered is greater than quantity in stock for one or more products in the order.');
    }
  };

  useSubscription(ORDER_UPDATED_SUBSCRIPTION, {
    variables: { orderId },
    onSubscriptionData: _.debounce(({ subscriptionData }) => {
      handleFinalOrderProcess(subscriptionData);
    }, 10000)
  });

  useSubscription(ORDER_UPLOADED_SUBSCRIPTION, {
    variables: { orderId },
    onSubscriptionData: _.debounce(({ subscriptionData }) => {
      const { data } = subscriptionData ?? {};
      const { uploadStatus } = data?.orderUploaded ?? {};
      if ((uploadStatus === 'completed' || uploadStatus === 'failed') && !ordering) {
        refetch();
        setState((s) => ({ ...s, orderProcessingDialog: false }));
      }
    }, 10000)
  });

  useEffect(() => {
    if (orderData && orderData.order) {
      const {
        order,
        isBusinessLoanEligible,
        business: _business,
        cartOrderProductsCount
      } = orderData;
      const orderMeta = parseJson(order?.meta);
      const { deliveryLocations: deliveryLocs } = _business;
      const {
        addressLine1,
        addressLine2,
        city,
      } = orderMeta;

      const { supplierOrder } = order[0] || {};
      const { order: orderItems } = supplierOrder || {};
      const { id: orderID, paymentSummary, meta: individualMeta } = orderItems || {};
      const totalValue = parseJson(paymentSummary);
      const singleOrderMeta = parseJson(individualMeta);
      const {
        upload_status: csvUploadStatus,
        place_order_status: placeOrdertatus
      } = singleOrderMeta;
      if (csvUploadStatus === 'in_progress' || placeOrdertatus === 'PROCESSING') {
        const options = (csvUploadStatus === 'in_progress'
          ? { title: 'Uploading Orders', desc: 'Please wait while we upload your orders.' }
          : { title: 'Order Processing', desc: 'Please wait while we process your orders.' });
        setModalMessage(options);
        setState((s) => ({ ...s, orderProcessingDialog: true }));
      }
      dispatch({
        type: CartActionTypes.CART_COUNT,
        payload: cartOrderProductsCount
      });

      const deliveryAddressArray = [
        addressLine1,
        addressLine2,
        city
      ];
      setState((s) => ({
        ...s,
        addressLine1,
        addressLine2,
        city,
        selectedDeliveryLocation: deliveryLocs ? deliveryLocs[0]?.name : '',
        deliveryLocations: deliveryLocs,
        business: _business,
        deliveryAddress: deliveryAddressArray.filter((d) => !!d).join(', '),
        orderId: orderID,
        showLoan: isBusinessLoanEligible,
        businessUserId: bizId,
        cartProductsCount: cartOrderProductsCount,
        totalPaymentSummary: totalValue?.main_total,
        serviceFee: totalValue?.service_charge
      }));
      retrieveProducts(order);
    }

    if (savedCartData && savedCartData.saveCartOrder) {
      const { saveCartOrder } = savedCartData;
      setState((s) => ({ ...s, cartOrder: saveCartOrder, dataCartLoading: false }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderData, savedCartData]);

  const getOutletOption = () => {
    const optionsBox = { name: 'deliverTo', label: 'Select Pharmacy', options: [] };
    const { deliveryLocations } = state;
    if (platform === 'marketplace') {
      deliveryLocations.forEach((_deliveryLocation) => {
        const { name, contacts, profile } = _deliveryLocation;
        const { from_remote: remotePos } = parseProfileJson(profile);
        const { address_line_1: addrs, city, country } = parseJson(contacts);
        optionsBox.options.push({ name, address: `${addrs}, ${city}, ${country}`, remotePos });
      });
    } else {
      deliveryLocations.forEach((_deliveryLocation) => {
        optionsBox.options.push(_deliveryLocation.name);
      });
    }

    return optionsBox;
  };

  const selectDosage = () => {
    const optionsBox = { name: 'dosageForm', options: [] };
    dosages.forEach((dosage) => {
      optionsBox.options.push(dosage.name);
    });
    return optionsBox;
  };

  const handleSwitchOrderProductSupplier = (_variables) => {
    switchOrderProductSupplier({
      variables: _variables,
      optimisticResponse: { __typename: 'Mutation' }
    }).then(({ data }) => {
      const {
        switchOrderProductSupplier: {
          order: { id, meta: ordermeta, supplierorderSet }
        }
      } = data;

      const orderMeta = parseJson(ordermeta);

      setState((s) => ({
        ...s,
        updateLoading: false,
        deliveryAddress: orderMeta.delivery_address,
        orderId: id
      }));

      retrieveProducts(supplierorderSet);
      toast.success('Items updated successfully.');
      window.location.reload();
    }).catch((err) => {
      toast.error(err?.message);
      setState((s) => ({ ...s, updateLoading: false }));
    });
  };

  const handleChangeDeliveryLocation = (name) => {
    setState((s) => ({ ...s, selectedDeliveryLocation: name }));
  };

  const getSelectedDeliveryLocationInfo = () => {
    const { deliveryLocations } = state;
    return deliveryLocations.find((_deliveryLocation) => _deliveryLocation.name === selectedDeliveryLocation);
  };

  const handleOpenViewModal = (event) => {
    setState((s) => ({ ...s, viewMoreButton: viewMoreButton ? null : event?.currentTarget }));
  };

  const handleEditDeliveryLocation = (selectedDeliveryLocationInfo) => {
    handleOpenViewModal();
    setOpenOutletModal(true);
    setExistingOutlet(selectedDeliveryLocationInfo);
  };

  const handleDeliveryAddress = () => {
    setOpenOutletModal(true);
    setExistingOutlet(null);
  };
  const token = localStorage.getItem('oga_rest_token');

  const handleCartList = async () => {
    try {
      await handleDownloadUtil(OgaToken.SERVER_LINK, 'download_order_save_cart', token, `${orderId}.pdf`);
    } catch (err) {
      toast.error('Invoice download failed');
      console.error('Invoice download failed:', err);
    }
  };


  const handleClearCart = () => {
    deleteCart({
      variables: { orderId },
      optimisticResponse: { __typename: 'Mutation' }
    }).then(() => {
      toast.success('Cart items cleared successfully.');
      refetch();
      dispatch({
        type: CartActionTypes.CART_COUNTER,
        payload: 0
      });
      setState(initialState);
    }).catch((err) => {
      toast.error(err?.message);
    });
  };

  const handleDeleteDeliveryLocation = (selectedDeliveryLocationInfo) => {
    deliveryLocation({
      variables: {
        deliveryLocationId: +selectedDeliveryLocationInfo.id
      },
      optimisticResponse: {
        __typename: 'Mutation'
      }
    }).then(() => {
      toast.success('Delivery location deleted successfully.');
      window.location.reload();
    }).catch((err) => {
      toast.error(err?.message);
    });
  };

  const handleDeleteAddressDialog = (event) => {
    setState((s) => ({
      ...s, deleteAddressDialog: deleteAddressDialog !== false ? false : Boolean(event.currentTarget), viewMoreButton: null
    }));
  };

  const handleClearCartDialog = (event) => {
    setState((s) => ({
      ...s, clearCartDialog: clearCartDialog !== false ? false : Boolean(event.currentTarget)
    }));
  };

  const handleCartDialog = (event) => {
    setState((s) => ({
      ...s, actionPopper: actionPopper ? null : event.currentTarget
    }));
  };

  const renderSelectDeliveryLocationInfo = () => {
    const selectedDeliveryLocationInfo = getSelectedDeliveryLocationInfo();
    if (selectedDeliveryLocationInfo) {
      let { contacts } = selectedDeliveryLocationInfo;
      try {
        contacts = reformatAddress(contacts);
      } catch (e) {
        return null;
      }
      return (
        <OutletInfoContainer container>
          <Grid item xs={9}>
            <OutletContactHeader>Delivery Address</OutletContactHeader>
            <br />
            <Grid container>
              <OutletContactLine>
                {contacts.address_line_1}
                ,
                &nbsp;
              </OutletContactLine>
              <OutletContactLine>
                {contacts.city}
                ,
                &nbsp;
              </OutletContactLine>
              <OutletContactLine>
                {contacts.country}
                ,
                &nbsp;
              </OutletContactLine>
            </Grid>
          </Grid>
          <Grid container item xs={3} style={{ justifyContent: 'space-around', alignItems: 'center' }}>
            <Default>Default</Default>
            <ViewMore onClick={(event) => handleOpenViewModal(event)}>...</ViewMore>
          </Grid>
        </OutletInfoContainer>
      );
    }
    return null;
  };

  const updateCartTotal = (totalProductCount) => {
    dispatch({
      type: CartActionTypes.CART_COUNT,
      payload: totalProductCount
    });
  };

  const handleUpdateOrderProducts = () => {
    const { toBeDeletedItem } = state;

    const mutationVariables = {
      orderProductIds: toBeDeletedItem,
      orderId
    };

    if (manualCart) {
      mutationVariables.isManualOrder = true;
    }
    setState((s) => ({ ...s, updateLoading: true }));

    deleteCartProduct({
      variables: mutationVariables,
      optimisticResponse: {
        __typename: 'Mutation'
      }
    }).then(({ data }) => {
      const {
        deleteCartProduct: {
          totalProductCount,
          order: {
            meta: ordermeta,
          }
        }
      } = data;

      updateCartTotal(totalProductCount);
      const orderMeta = parseJson(ordermeta);
      setState((s) => ({
        ...s,
        updateLoading: false,
        deliveryAddress: orderMeta.delivery_address
      }));
      toast.success('Items updated successfully.');
      refetch();
    }).catch((err) => {
      toast.error(err?.message);
      setState((s) => ({ ...s, updateLoading: false }));
    });
  };

  const handlePlaceOrderProducts = () => {
    const role = localStorage.getItem('oga_user_role');
    const ogaOrderingForAffiliateKey = 'oga_ordering_for_affiliate';
    const businessRaw = localStorage.getItem(ogaOrderingForAffiliateKey);
    if (businessRaw === null && role === 'oga-pharmacy-admin') {
      setState((s) => ({ ...s, showPlaceOrderDialog: false }));
      return toast.error('Select an affiliate');
    }
    const _business = businessRaw ? JSON.parse(businessRaw) : null;
    const {
      acceptedExtraProducts,
      businessUserId
    } = state;

    const selectedDeliveryLocationInfo = getSelectedDeliveryLocationInfo();
    const extraActions = [];
    const affectedSuppliers = [];
    const extraProductsMeta = acceptedExtraProducts;
    extraPlaceOrderActions.forEach((action) => extraActions.push(action));
    toBePrintedManualSupplierOrderForms.forEach((affectedSupplier) => affectedSuppliers.push(affectedSupplier));
    const mutationVariables = _business ? {
      businessId: _business.id,
      deliveryLocationId: +selectedDeliveryLocationInfo.id,
      extraActions,
      affectedSuppliers,
      extraProductsMeta,
      creditScoreBusiness: +_business.id,
      idempotentKey: TabSessionIdempotentKey
    } : {
      cart: [],
      deliveryLocationId: +selectedDeliveryLocationInfo.id,
      extraActions,
      affectedSuppliers,
      extraProductsMeta,
      creditScoreBusiness: +businessUserId,
      idempotentKey: TabSessionIdempotentKey
    };
    if (manualCart) {
      mutationVariables.isManualOrder = true;
    }
    clearLocalStorageIdempotentEntries('place_order_processing_failed');
    clearLocalStorageIdempotentEntries('place_order_processing_completed');
    setState((s) => ({ ...s, placeLoading: true }));
    setModalMessage({ title: 'Order Processing', desc: 'Please wait while we process your orders.' });
    handleOrderProcessingDialog();

    const mutationFunc = _business ? placeAffiliateOrderProducts : placeOrderProducts;
    mutationFunc({
      variables: mutationVariables,
      optimisticResponse: { __typename: 'Mutation' }
    }).then(({ data }) => {
      dispatch({ type: CartActionTypes.CART_STATUS, payload: 'order_processing' });
      let message = '';
      let id = '';
      if (_business) {
        message = data.placeAffiliateOrderProducts.message;
        id = data.placeAffiliateOrderProducts?.order?.id;
      } else {
        // message = data.placeOrderProducts.message;
        message = 'Please wait while we process your orders!';
        id = data.placeOrderProducts?.order?.id;
      }
      setState((s) => ({
        ...s, showPlaceOrderDialog: false, placeLoading: false, newOrderId: id
      }));
      toast.custom(<CustomToast>{message}</CustomToast>);
      if (linkedPosAccountsTotal > 0) {
        setAddrsConfirmDialog(false);
        setSuccessDialog(true);
      }
    }).catch((err) => {
      refetch();
      toast.error(err?.message);
    }).finally(() => {
      setState((s) => ({
        ...s, showPlaceOrderDialog: false, placeLoading: false
      }));
    });
  };

  const renderEmptyCart = () => (
    <EmptyCardPaper elevation={2}>
      {loading || savedCartLoading ? (
        <LoaderGrid item container>
          <CircularProgressLoader
            disableShrink
            size={100}
          />
        </LoaderGrid>
      ) : (
        <>
          <TableWrapper>
            <TableComponent aria-label="affiliates table">
              <TableHeader>
                <MainTableRow>
                  <Header> Product Item</Header>
                  <Header> Quantity</Header>
                  <Header> Unit Cost</Header>
                  <Header> Price</Header>
                  <Header>Action</Header>
                </MainTableRow>
              </TableHeader>
            </TableComponent>
          </TableWrapper>
          <TextWrapper container>
            <Grid>
              <EmptyCartImg alt="empty cart" src={EmptyCart} />
              <TextHeader>Cart is Empty</TextHeader>
            </Grid>
            <TextBody>Hi, Your cart is empty and your product will show here when you add them to cart</TextBody>
            <ShopNowButton
              variant="contained"
              onClick={() => navigate(backRoute)}
              cartIndex
            >
              Shop Now
            </ShopNowButton>
          </TextWrapper>
        </>
      )}
    </EmptyCardPaper>
  );

  const handleCloseDeleteDialog = () => {
    setState((s) => ({
      ...s,
      showDeleteDialog: false,
      toBeDeletedItem: null
    }));
  };

  const handleDeleteProduct = () => {
    const { toBeDeletedItem } = state;
    const updatedCart = cart.map((cartItem) => {
      if (Number(cartItem.orderProductId) === Number(toBeDeletedItem)) {
        return { ...cartItem, quantity: 0 };
      }
      return cartItem;
    });
    setState((s) => ({ ...s, cart: updatedCart }));
    handleCloseDeleteDialog();
    handleUpdateOrderProducts();
  };

  const handleIncrementProductQuantityInCart = (product) => {
    const {
      id, batchId, quantityInStock, quantity, unitCost
    } = product;
    const calculateUpdatedQuantity = () => {
      if (platform !== 'erp' && +quantity < +quantityInStock) {
        return +quantity + 1;
      }
      toast.error('Requested quantity not available');
      return +quantity;
    };
    const updatedQuantity = calculateUpdatedQuantity();
    const totalOrderVal = +quantity < +quantityInStock ? Number(totalPaymentSummary) + Number(unitCost) : totalPaymentSummary;

    const updatedCart = cart.map((item) => (item.id === id
      ? { ...item, quantity: updatedQuantity }
      : item));
    setState((s) => ({
      ...s,
      cart: updatedCart,
      totalPaymentSummary: totalOrderVal
    }));

    if (+quantity < +quantityInStock) {
      updateOrder({
        variables: {
          productId: +id,
          quantity: updatedQuantity,
          overwriteQuantity: true,
          ...(batchId && { batchId })
        }
      });
    }
  };

  const handleChangeProductQuantityInputValue = (product, value) => {
    let newValue = 0;
    if (Number(value)) newValue = value;
    else newValue = 1;
    const {
      id, batchId, unitCost, quantityInStock, quantity
    } = product;
    if (newValue > quantityInStock && platform !== 'erp') return toast.error('Requested quantity not available');
    const quantityDifference = Number(newValue) - Number(quantity);
    const updatedTotalPaymentSummary = Number(totalPaymentSummary) + (quantityDifference * Number(unitCost));
    setState((s) => ({
      ...s,
      totalPaymentSummary: updatedTotalPaymentSummary,
      cart: cart.map((cartItem) => ((cartItem.id === id)
        ? { ...cartItem, quantity: newValue }
        : cartItem))
    }));

    if (product) {
      updateOrder({
        variables: {
          productId: +id,
          quantity: newValue,
          overwriteQuantity: true,
          ...(batchId && { batchId }),
        }
      });
    }
  };

  const handleDecrementProductQuantityInCart = (product) => {
    const {
      id, batchId, quantity, unitCost
    } = product;
    if (quantity > 1) {
      setState((s) => ({
        ...s,
        totalPaymentSummary: Number(totalPaymentSummary) - Number(unitCost),
        cart: cart.map((cartItem) => (cartItem.id === id
          ? { ...cartItem, quantity: cartItem.quantity - 1 }
          : cartItem))
      }));
      updateOrder({
        variables: {
          productId: +id,
          quantity: quantity - 1,
          overwriteQuantity: true,
          ...(batchId && { batchId }),
        }
      });
    }
  };

  const handleOpenDeleteDialog = (orderProductId) => {
    setState((s) => ({
      ...s,
      showDeleteDialog: true,
      toBeDeletedItem: orderProductId
    }));
  };

  const handleOpenSaveCartDialog = () => {
    setState((s) => ({ ...s, saveDialog: true }));
  };

  const handleCloseSaveCartDialog = () => {
    setState((s) => ({ ...s, saveDialog: false }));
  };

  const handleOpenSaveCartDialogView = () => {
    setState((s) => ({ ...s, savedCartDialog: true }));
  };

  const handleCloseSaveCartDialogView = () => {
    setState((s) => ({ ...s, savedCartDialog: false }));
  };

  const handleSave = () => {
    setState((s) => ({ ...s, cart: [] }));
    handleClearCart();
  };

  const handleOpenCartSavedDialog = () => {
    setState((s) => ({ ...s, cartSaved: true }));
  };

  const handleCloseCartSavedDialog = () => {
    setState((s) => ({ ...s, cartSaved: false }));
  };

  const handleOpenNoSavedCartDialog = () => {
    setState((s) => ({ ...s, noSavedCart: true }));
  };

  const handleCloseNoSavedCartDialog = () => {
    setState((s) => ({ ...s, noSavedCart: false }));
  };

  const handleDirectPlaceOrder = () => {
    if (linkedPosAccountsTotal > 0) {
      setState((s) => ({
        ...s,
        placeManualOrder: false,
        ordering: true
      }));
      setAddrsConfirmDialog(true);
      return;
    }
    setState((s) => ({
      ...s,
      placeManualOrder: false,
      showPlaceOrderDialog: true,
      ordering: true
    }));
  };

  const handleOpenPlaceOrderDialog = () => {
    const deliveryLocationInfo = getOutletOption();
    const { options } = deliveryLocationInfo;
    if (options.length === 0) return toast.error('Select delivery location');
    if (manualCart) {
      setState((s) => ({ ...s, placeManualOrder: true }));
    } else {
      handleDirectPlaceOrder();
    }
  };

  const handleClosePlaceOrderDialog = () => {
    setState((s) => ({ ...s, showPlaceOrderDialog: false }));
  };

  const closeDialogHandler = () => {
    setOpenOutletModal(false);
    setExistingOutlet(null);
  };

  const handleTogglePrintedManualSupplierOrderForms = (supplier) => {
    const exists = toBePrintedManualSupplierOrderForms.get(supplier);
    if (exists) {
      toBePrintedManualSupplierOrderForms.delete(supplier);
    } else {
      toBePrintedManualSupplierOrderForms.set(supplier, supplier);
    }
    setState((s) => ({ ...s, toBePrintedManualSupplierOrderForms }));
  };

  const getSelectedActionStyles = (action) => {
    const exists = extraPlaceOrderActions.get(action);
    if (!exists) {
      return { paddingTop: 20 };
    }
    return {
      borderRadius: 5,
      paddingTop: 20,
      padding: 5,
      backgroundColor: '#F4F4F4',
      opacity: 0.8,
      minWidth: 100
    };
  };

  const handleToggleExtraPlaceOrderActions = (action) => {
    const exists = extraPlaceOrderActions.get(action);
    if (exists) {
      extraPlaceOrderActions.delete(action);
    } else {
      extraPlaceOrderActions.set(action, action);
    }
    if (action === 'print') {
      setState((s) => ({
        ...s,
        printSupplierOrder: !!toBePrintedManualSupplierOrderForms.size,
        extraPlaceOrderActions
      }));
      return;
    }
    setState((s) => ({ ...s, extraPlaceOrderActions }));
  };

  const handleChangeExtraProduct = (event, index) => {
    const { name, value } = event.target;
    const copiedExtraProducts = [...extraProducts];
    copiedExtraProducts[index][name] = value;
    setState((s) => ({ ...s, extraProducts: copiedExtraProducts }));
  };

  const handleDeleteExtraProduct = (index) => {
    const copiedExtraProducts = extraProducts.filter((product, ind) => ind !== index);
    setState((s) => ({ ...s, extraProducts: copiedExtraProducts }));
  };

  const handleAddExtraProduct = () => {
    const copiedExtraProducts = [...extraProducts];
    copiedExtraProducts.push(generateExtraProduct());
    setState((s) => ({ ...s, extraProducts: copiedExtraProducts }));
  };

  const cancelExtraProducts = () => {
    const copiedExtraProducts = [generateExtraProduct(), generateExtraProduct(), generateExtraProduct(), generateExtraProduct()];
    toast.success('Extra products removed successfully.');
    setState((s) => ({
      ...s,
      extraProducts: copiedExtraProducts, acceptedExtraProducts: [], wantExtraProducts: false,
    }));
  };

  const saveExtraProducts = () => {
    const copiedExtraProducts = [...extraProducts];
    toast.success('Extra products successfully added.');
    setState((s) => ({
      ...s,
      extraProducts: copiedExtraProducts, acceptedExtraProducts: copiedExtraProducts
    }));
  };

  const handleWantExtraProduct = () => {
    setState((s) => ({ ...s, wantExtraProducts: !wantExtraProducts }));
  };

  const handleClearAllCart = () => {
    setState((s) => ({ ...s, cart: [] }));
    setState((s) => ({ ...s, clearCartDialog: false }));
    handleClearCart();
  };

  const renderManualModalPlaceOrderSuppliers = () => {
    const uniqueSuppliers = [...new Set([...cart.map((item) => item.supplier)])];
    return uniqueSuppliers.map((supplier) => (
      <ManualSupplierItem key={supplier} item>
        <Grid container direction="column" justifyContent="center" alignItems="center">
          <Grid item>
            <ManualMethodImage src={SupplierOrderForm} />
          </Grid>
          <Grid item>{supplier?.trim().split(' ')[0]}</Grid>
          <Grid>
            <Checkbox
              checked={toBePrintedManualSupplierOrderForms.get(supplier)}
              onChange={() => handleTogglePrintedManualSupplierOrderForms(supplier)}
              color="primary"
            />
          </Grid>
        </Grid>
      </ManualSupplierItem>
    ));
  };

  const ChangeQuantity = (index, value) => {
    if (value < 1) return;
    const copiedExtraProducts = [...extraProducts];
    copiedExtraProducts[index].quantity = value;
    setState((s) => ({ ...s, extraProducts: copiedExtraProducts }));
  };

  const changeToInt = (event, index) => {
    const { name, value } = event.target;
    const copiedExtraProducts = [...extraProducts];
    copiedExtraProducts[index][name] = parseInt(value, 10);
    setState((s) => ({ ...s, extraProducts: copiedExtraProducts }));
  };

  useEffect(() => {
    if (searchText && searchText.length >= 3) {
      setSearchValue(searchText);
    } else if (!searchText) {
      setSearchValue('');
    }
  }, [searchText]);

  const handleSearch = (text) => {
    setSearchText(text);
  };

  const renderExtraProductRow = (row, index) => (
    <TableRow style={{ padding: 0, borderBottom: 0 }} key={`key${index}`}>
      <TableCell style={{ borderBottom: 0 }}>
        <Grid>
          <input
            type="text"
            name="brandName"
            id="brandname"
            value={row.brandName}
            onChange={(event) => handleChangeExtraProduct(event, index)}
            placeholder="Name of medicine"
            style={{
              background: '#F0F0F0', width: '100%', paddingLeft: '20px', paddingTop: '15px', paddingBottom: '15px', fontSize: '15px', border: 'none',
            }}
          />
        </Grid>
      </TableCell>
      <TableCell style={{ borderBottom: 0 }}>
        <Grid>
          <ChangeOutletRow>
            <ReturnSelectField
              field={selectDosage()}
              value={row.dosageForm}
              showCheckBox={false}
              label="Select"
              handleCreditDaysOpen={() => ({})}
              fullWidth
              handleChange={(event) => handleChangeExtraProduct(event, index)}
            />
          </ChangeOutletRow>
        </Grid>
      </TableCell>
      <TableCell style={{ borderBottom: 0 }}>
        <Grid>
          <input
            type="text"
            name="strength"
            id="strength"
            value={row.strength}
            onChange={(event) => handleChangeExtraProduct(event, index)}
            placeholder="mg/iu"
            style={{
              background: '#F0F0F0', width: '100%', paddingLeft: '20px', paddingTop: '15px', paddingBottom: '15px', fontSize: '15px', border: 'none',
            }}
          />
        </Grid>
      </TableCell>
      <TableCell style={{ borderBottom: 0 }}>
        <Grid>
          <input
            type="text"
            name="packSize"
            id="packsize"
            value={row.packSize}
            onChange={(event) => handleChangeExtraProduct(event, index)}
            placeholder="e.g 30 Tablets"
            style={{
              background: '#F0F0F0', width: '100%', paddingLeft: '20px', paddingTop: '15px', paddingBottom: '15px', fontSize: '15px', border: 'none',
            }}
          />
        </Grid>
      </TableCell>
      <TableCell style={{
        borderBottom: 0, alignContent: 'center'
      }}
      >
        <Grid>
          <CartItemButtonContainer>
            <CartItemButton onClick={() => ChangeQuantity(index, row.quantity - 1)} style={{ float: 'none' }}>-</CartItemButton>
            <input
              type="number"
              name="quantity"
              id="quantity"
              min={1}
              value={row.quantity}
              onChange={(event) => changeToInt(event, index)}
              style={{
                background: '#F0F0F0', width: '40%', paddingLeft: '10px', paddingTop: '9px', paddingBottom: '15px', fontSize: '20px', border: 'none', marginTop: '5px'
              }}
            />
            <CartItemButton onClick={() => ChangeQuantity(index, row.quantity + 1)} style={{ float: 'none' }}>+</CartItemButton>
          </CartItemButtonContainer>
        </Grid>
      </TableCell>
      <TableCell style={{
        borderBottom: 0, alignContent: 'center'
      }}
      >
        <Grid>
          <Delete onClick={() => handleDeleteExtraProduct(index)} style={{ cursor: 'pointer' }} />
        </Grid>
      </TableCell>
    </TableRow>
  );

  const openPayhippo = () => {
    const orderTotal = cart.reduce((sum, obj) => sum + (Number(obj.quantity) * Number(manualCart ? obj.orderCost : obj.unitCost)), 0) * priceFactor;
    const cartAmount = serviceFee + deliveryFee + orderTotal;
  };

  const handleOpenMCF = () => {
    setState((s) => ({ ...s, openMCF: true }));
  };

  const handleCloseDialog = () => {
    setState((s) => ({ ...s, openMCF: false }));
  };

  const handleBannerClick = () => bannerUrl && window.open(bannerUrl);

  const renderSupplierContainers = (_priceFactor) => {
    const supplierCategories = _.groupBy(cart, 'supplier');
    return Object.entries(supplierCategories)?.map(([key, value]) => {
      const isErp = manualCart && (key !== 'OGApharmacy');
      const plat = isErp ? 'erp' : 'marketplace';
      const supplierSubTotal = value.reduce((sum, obj) => {
        const product = new Product(obj, plat);
        const unitPrice = isErp ? product.orderCost : product.unitCost;
        return sum + obj.quantity * unitPrice;
      }, 0) * _priceFactor;

      return (
        <SupplierCard key={key}>
          <Hidden mdDown>
            <TableWrapper>
              <TableComponent aria-label="affiliates table">
                <TableHeader>
                  <MainTableRow style={{ border: 'none' }}>
                    <Header>Product Name</Header>
                    <Header>Quantity</Header>
                    <Header>Unit Cost</Header>
                    <Header>Price</Header>
                    <Header>Actions</Header>
                  </MainTableRow>
                </TableHeader>
                <TableBody>
                  {value.filter((p) => p.quantity > 0).map((productItem) => (
                    <ProductItem
                      key={productItem}
                      product={productItem}
                      platform={plat}
                      switchOrderProductSupplier={handleSwitchOrderProductSupplier}
                      incrementProductQuantity={handleIncrementProductQuantityInCart}
                      decrementProductQuantity={handleDecrementProductQuantityInCart}
                      openDeleteDialog={handleOpenDeleteDialog}
                      closeDeleteDialog={handleCloseDeleteDialog}
                      changeInputQuantity={handleChangeProductQuantityInputValue}
                      refetch={refetch}
                    />
                  ))}
                </TableBody>
              </TableComponent>
            </TableWrapper>
          </Hidden>
          <Hidden mdUp>
            <Grid container>
              {value.filter((p) => p.quantity > 0).map((productItem) => (
                <ProductItem
                  key={productItem}
                  product={productItem}
                  platform={plat}
                  switchOrderProductSupplier={handleSwitchOrderProductSupplier}
                  incrementProductQuantity={handleIncrementProductQuantityInCart}
                  decrementProductQuantity={handleDecrementProductQuantityInCart}
                  openDeleteDialog={handleOpenDeleteDialog}
                  closeDeleteDialog={handleCloseDeleteDialog}
                  changeInputQuantity={handleChangeProductQuantityInputValue}
                />
              ))}
            </Grid>
          </Hidden>
          <ProductSubTotalWrapper>
            <ProductSubTotalHeaders>
              <ProductColumnHeaderTitle style={{ width: '20%' }}>
                Subtotal
              </ProductColumnHeaderTitle>
              <ProductColumnHeaderTitle style={{ width: '25%', display: 'flex', justifyContent: 'end' }}>
                {`₦ ${currencyFormatter(supplierSubTotal)}`}
              </ProductColumnHeaderTitle>
            </ProductSubTotalHeaders>
          </ProductSubTotalWrapper>
        </SupplierCard>
      );
    });
  };
  const orderTotal = (totalPaymentSummary) - serviceFee;
  const taxTotal = business && business.tax ? orderTotal * (business.tax / 100) : 0;
  const grandTotal = orderTotal + deliveryFee + serviceFee;
  const open = Boolean(viewMoreButton);
  const selectedDeliveryLocationInfo = getSelectedDeliveryLocationInfo();
  const therapeuticTypes = cart.slice(0, 5)?.filter((item) => item.therapeuticType !== null)?.map((item) => item.therapeuticType?.id);
  const similarProductIds = cart.slice(0, 5).map((item) => item.id);

  return (
    <MainContent>
      <div>
        <CartGridContainer>
          <CartGridContainerHeader container sm={2} md={1}>
            <BackArrowIconContainer item sm={2} md={1} onClick={() => navigate(-1)} className="mpAdmin-uat-cartPage-back">
              <KeyboardArrowLeft />
              <ArrowBackText>Back</ArrowBackText>
            </BackArrowIconContainer>
          </CartGridContainerHeader>
          <CartItemCountContainer container xs={12}>
            <CartItemContainer item container xs={12} lg={8}>
              <PageTitleText>Your Cart</PageTitleText>
              {cart?.length ? (
                <CartItemWrapper style={{ marginTop: '10px' }}>
                  <CartImage src={Cart} />
                  <CartItemCountText>
                    {`${cartProductsCount} ${cartProductsCount > 1 ? 'Items' : 'Item'} in cart`}
                  </CartItemCountText>
                </CartItemWrapper>
              ) : ''}
            </CartItemContainer>
            <ClearBox xs={12} lg={4} container style={{ justifyContent: 'flex-end' }}>
              {cart?.length ? (
                <Grid xs={9} md={8} style={{ marginTop: '0.5rem' }}>
                  <ClearAllCart onClick={(e) => handleClearCartDialog(e)}>
                    Clear Cart
                  </ClearAllCart>
                </Grid>
              ) : ''}
              {manualCart === false ? (
                <Grid container xs={3} md={4} style={{ justifyContent: 'flex-end' }}>
                  <IconWrapper variant="text">
                    <Hidden lgDown><Dots fontSize="large" onClick={(e) => handleCartDialog(e)} /></Hidden>
                    <Hidden mdUp><CartDot style={{ fontSize: '6rem' }} onClick={(e) => handleCartDialog(e)} /></Hidden>
                    <ActionPopper
                      handleClose={handleCartDialog}
                      actionPopper={actionPopper}
                      handleAction={handleOpenSaveCartDialog}
                      handleActionView={handleOpenSaveCartDialogView}
                      handleCartList={handleCartList}
                      cart={cart}
                      handleNoCart={handleOpenNoSavedCartDialog}
                      cartOrder={cartOrder}
                    />
                  </IconWrapper>
                </Grid>
              ) : ''}
            </ClearBox>
          </CartItemCountContainer>
          <CartContainer container xs={12}>
            <Grid container spacing={4} style={{ marginLeft: '.5rem' }}>
              <Grid lg={8}>
                <SupplierCardPaper elevation={2}>
                  <Hidden mdUp>
                    <CartHeader>
                      Cart Items
                    </CartHeader>
                  </Hidden>
                  <SearchGrid item>
                    <CustomSearchField
                      name="search"
                      value={searchText}
                      fullWidth
                      placeholder="Search"
                      handleChange={(e) => handleSearch(e?.target?.value)}
                    />
                  </SearchGrid>
                  {cart.length === 0 || loading || savedCartLoading
                    ? renderEmptyCart()
                    : renderSupplierContainers(priceFactor)}
                  <FooterGrid item container>
                    <TablePagination
                      total={cartProductsCount}
                      pageCount={pageCount}
                      setPageCount={setPageCount}
                      pageNumber={pageNumber}
                      setPageNumber={setPageNumber}
                      options={[10, 20, 50, 99]}
                    />
                  </FooterGrid>
                </SupplierCardPaper>

                {cart?.length
                  ? (
                    <DeliveryAddressCard style={{ marginTop: '1rem' }}>
                      <DeliveryAddressCardSection elevation={2}>
                        <DeliveryAddressCardHeader>
                          <DeliveryAddressCardHeaderTitle>Delivery Location</DeliveryAddressCardHeaderTitle>
                          <Hidden lgDown>
                            <DeliveryAddressCardHeaderSubTitle
                              onClick={handleDeliveryAddress}
                              type="button"
                              className="mpAdmin-uat-cartPage-addDeliveryLocation"
                            >
                              <DeliveryIcon />
                              Add delivery location
                            </DeliveryAddressCardHeaderSubTitle>
                          </Hidden>
                        </DeliveryAddressCardHeader>
                        <Grid style={{ marginBottom: '20px' }}>
                          <SelectOutletText>Select the outlet you want your order to be delivered to from the dropdown below</SelectOutletText>
                          <Hidden mdUp>
                            <DeliveryAddressCardHeaderSubTitle
                              onClick={handleDeliveryAddress}
                              type="button"
                              className="mpAdmin-uat-cartPage-addDeliveryLocation"
                            >
                              <DeliveryIcon />
                              Add delivery location
                            </DeliveryAddressCardHeaderSubTitle>
                          </Hidden>
                          <ChangeOutletRow>
                            <ReturnSelectField
                              field={getOutletOption()}
                              value={selectedDeliveryLocation}
                              showCheckBox={false}
                              // placeholder="Select Pharmacy"
                              handleCreditDaysOpen={() => ({})}
                              fullWidth
                              handleChange={handleChangeDeliveryLocation}
                              cart
                              className="mpAdmin-uat-cartPage-selectDeliveryOutlet"
                            />
                          </ChangeOutletRow>
                        </Grid>
                        {renderSelectDeliveryLocationInfo()}
                      </DeliveryAddressCardSection>
                    </DeliveryAddressCard>
                  ) : ''}
              </Grid>
              <Hidden mdDown>
                <DeliveryAddressCard2 xs={12} lg={4}>
                  <PaymentCardSection elevation={2}>
                    <PaymentSummary
                      cart={cart}
                      orderTotal={orderTotal}
                      serviceFee={serviceFee}
                      deliveryFee={deliveryFee}
                      taxTotal={taxTotal}
                      grandTotal={grandTotal}
                      handleOpenPlaceOrderDialog={handleOpenPlaceOrderDialog}
                      handleDirectPlaceOrder={handleDirectPlaceOrder}
                      placeLoading={placeLoading}
                      updateLoading={updateLoading}
                      platform={platform}
                    />
                  </PaymentCardSection>
                  {bannerImage && (
                    <BannerBox>
                      <BannerImage alt="cart banner" src={bannerImage} onClick={handleBannerClick} />
                    </BannerBox>
                  )}
                </DeliveryAddressCard2>
              </Hidden>

              <Hidden mdUp>
                <DeliveryAddressCard2 xs={12} lg={4}>
                  <PaymentCardSection elevation={2}>
                    <PaymentSummary
                      cart={cart}
                      orderTotal={orderTotal}
                      serviceFee={serviceFee}
                      deliveryFee={deliveryFee}
                      taxTotal={taxTotal}
                      grandTotal={grandTotal}
                      handleOpenPlaceOrderDialog={handleOpenPlaceOrderDialog}
                      placeLoading={placeLoading}
                      updateLoading={updateLoading}
                      backRoute={backRoute}
                      handleDirectPlaceOrder={handleDirectPlaceOrder}
                    />
                  </PaymentCardSection>
                </DeliveryAddressCard2>
              </Hidden>
            </Grid>
          </CartContainer>
          {canUseMastersList && (
            <Grid>
              <div>
                <h3 style={{ marginBottom: ' 10px' }}>
                  Want other products not on our list?
                  <Span
                    onClick={handleWantExtraProduct}
                    style={{
                      paddingLeft: '20px', marginTop: '90px', color: '#A3A3A3', width: '80%', cursor: 'pointer'
                    }}
                  >
                    <u>Click here</u>
                  </Span>
                </h3>
              </div>
              {wantExtraProducts && (
                <AddProductCard>
                  <AddProductCardSection>
                    <TableContainerWrapper>
                      <TableComponent aria-label="additional products">
                        <MainTableHead>
                          <TableRow>
                            <HeaderCell style={{ margin: 0, borderBottom: 0 }}>Product Name</HeaderCell>
                            <HeaderCell style={{ borderBottom: 0 }}>Dosage Form</HeaderCell>
                            <HeaderCell style={{ borderBottom: 0 }}>Strength</HeaderCell>
                            <HeaderCell style={{ borderBottom: 0 }}>Pack Size</HeaderCell>
                            <HeaderCell style={{ borderBottom: 0, align: 'center' }}>Qty</HeaderCell>
                            <HeaderCell style={{ borderBottom: 0, align: 'center' }} />
                          </TableRow>
                        </MainTableHead>
                        <TableBody>
                          {extraProducts.map((row, index) => renderExtraProductRow(row, index))}
                        </TableBody>
                      </TableComponent>
                    </TableContainerWrapper>
                    <Span
                      role="button"
                      tabIndex={0}
                      onKeyPress={() => ({})}
                      onClick={handleAddExtraProduct}
                      style={{
                        paddingLeft: '20px', marginTop: '90px', color: '#A3A3A3', width: '80%', cursor: 'pointer'
                      }}
                    >
                      <u>Add Product</u>
                    </Span>
                    {' '}
                    <CheckCircleOutlineRoundedIcon
                      onClick={saveExtraProducts}
                      style={{
                        float: 'right', cursor: 'pointer', paddingLeft: '30px', fontSize: '35px', width: '5%'
                      }}
                    />
                    <HighlightOffRoundedIcon
                      onClick={cancelExtraProducts}
                      style={{
                        float: 'right', cursor: 'pointer', paddingLeft: '30px', fontSize: '35px', width: '5%',
                      }}
                    />
                  </AddProductCardSection>
                </AddProductCard>
              )}
            </Grid>
          )}
          {platform !== 'erp' ? (
            <SimilarProducts
              searchProductIds={similarProductIds}
              therapeuticTypes={therapeuticTypes}
              cart
              refetchCart={refetch}
            />
          ) : ''}
        </CartGridContainer>
        <CreateOutletModal
          cart
          dialogOpen={openOutletModal}
          existingOutlet={existingOutlet}
          closeDialog={closeDialogHandler}
        />

        <Dialog
          open={showDeleteDialog}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleCloseDeleteDialog}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle>
            <TitleText>Delete Product</TitleText>
          </DialogTitle>
          <DialogContent>
            <ContentText id="alert-dialog-slide-description">
              Are you sure you want to delete this product from the cart?
            </ContentText>
          </DialogContent>
          <DialogActions>
            <DialogCancelButton
              onClick={handleCloseDeleteDialog}
            >
              <DialogActionButtonText>
                Cancel
              </DialogActionButtonText>
            </DialogCancelButton>

            <DialogOkButton
              onClick={() => handleDeleteProduct()}
            >
              <DialogActionButtonText>
                OK
              </DialogActionButtonText>
            </DialogOkButton>
          </DialogActions>
        </Dialog>

        <Dialog
          open={showPlaceOrderDialog}
          TransitionComponent={Transition}
          fullWidth
          keepMounted
          onClose={handleCloseDeleteDialog}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle>
            <TitleText>Place Order</TitleText>
          </DialogTitle>
          <DialogContent>
            <ContentText id="alert-dialog-slide-description">
              Are you sure you want to place the order for the current products in the cart?
            </ContentText>
          </DialogContent>
          <DialogActions>
            <DialogCancelButton
              onClick={handleClosePlaceOrderDialog}
              disabled={placeLoading}
            >
              <DialogActionButtonText>
                Cancel
              </DialogActionButtonText>
            </DialogCancelButton>
            <DialogOkButton
              onClick={handlePlaceOrderProducts}
              disabled={placeLoading}
            >
              {placeLoading ? (
                <CircularProgressLoader
                  disableShrink
                  size={22}
                  thickness={5}
                />
              ) : (
                <DialogActionButtonText>
                  OK
                </DialogActionButtonText>
              )}
            </DialogOkButton>
          </DialogActions>
        </Dialog>

        <Dialog
          open={placeManualOrder}
          fullWidth
          maxWidth="sm"
          TransitionComponent={Transition}
          keepMounted
          onClose={handleCloseDeleteDialog}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <ManualDialogPadder>
            <ManualPlaceOrder id="alert-dialog-slide-title">Place order</ManualPlaceOrder>
            <Grid style={{ marginTop: '-10px' }}>
              Choose how you to want to send your order to suppliers
            </Grid>
          </ManualDialogPadder>

          <br />
          <Grid style={{ backgroundColor: '#F4F4F4' }}>
            <ManualDialogPadder container>
              {renderManualModalPlaceOrderSuppliers()}
            </ManualDialogPadder>
          </Grid>

          <ManualDialogPadder>
            <Grid container justifyContent="space-between">
              <Grid style={{ ...getSelectedActionStyles('pdf') }}>
                <Grid container direction="column" onClick={() => handleToggleExtraPlaceOrderActions('pdf')} justifyContent="center" alignItems="center">
                  <ManualMethodImage src={Pdf} />
                  <OptionText>Save as PDF</OptionText>
                </Grid>
              </Grid>
              <Grid item style={{ ...getSelectedActionStyles('email') }}>
                <Grid container direction="column" onClick={() => handleToggleExtraPlaceOrderActions('email')} justifyContent="center" alignItems="center">
                  <ManualMethodImage src={Email} />
                  <OptionText>Email</OptionText>
                </Grid>
              </Grid>
              <Grid item style={{ ...getSelectedActionStyles('print') }}>
                <Grid container direction="column" onClick={() => handleToggleExtraPlaceOrderActions('print')} justifyContent="center" alignItems="center">
                  <Grid style={{ minHeight: 75 }}><ManualMethodImage src={Print} /></Grid>
                  <OptionText>Print</OptionText>
                </Grid>
              </Grid>
            </Grid>
            <br />
            <br />
            <Grid container justifyContent="center">
              <ManualDialogCancelButton onClick={() => setState((s) => ({ ...s, placeManualOrder: false }))}>
                <DialogActionButtonText>
                  Cancel
                </DialogActionButtonText>
              </ManualDialogCancelButton>
              <ManualDialogDoneButton
                // disabled={!selectedAffiliate}
                onClick={handleDirectPlaceOrder}
              >
                <DialogActionButtonText>
                  Done
                </DialogActionButtonText>
              </ManualDialogDoneButton>
            </Grid>
            <br />
          </ManualDialogPadder>
        </Dialog>
        <Dialog
          open={printSupplierOrder}
          fullWidth
          maxWidth="md"
          TransitionComponent={Transition}
          keepMounted
          onClose={handleCloseDeleteDialog}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <SupplierOrderPrint
            printSupplierOrder={printSupplierOrder}
            orderId={orderId}
            cart={cart}
            handleSupplierOrderPrint={() => setState((s) => ({ ...s, printSupplierOrder: false }))}
            selectedDeliveryLocationInfo={getSelectedDeliveryLocationInfo()}
            toBePrintedManualSupplierOrderForms={toBePrintedManualSupplierOrderForms}
          />
        </Dialog>
        <RenderPopper
          open={open}
          anchorEl={viewMoreButton}
          onClickAway={() => handleOpenViewModal()}
          popperPlacement="right"
          modifiers={{
            offset: {
              enabled: true,
              offset: '0vw, 2vh',
            }
          }}
        >
          <GridWrapper container item>
            <EditContainer container item>
              <ItemWrapper onClick={() => handleEditDeliveryLocation(selectedDeliveryLocationInfo)}>
                <ItemTypo variant="subtitle1">
                  Edit Address
                </ItemTypo>
              </ItemWrapper>
            </EditContainer>

            <GridContainer container item>
              <ItemWrapper onClick={(e) => handleDeleteAddressDialog(e)}>
                <ItemTypo variant="subtitle1">
                  Delete Address
                </ItemTypo>
              </ItemWrapper>
            </GridContainer>

          </GridWrapper>
        </RenderPopper>
        {showLoan && (
          <Floater
            state={state}
            priceFactor={priceFactor}
            openPayhippo={openPayhippo}
            handleOpenMCF={handleOpenMCF}
            handleCloseDialog={handleCloseDialog}
          />
        )}
        <ConfirmDialog
          handleClose={handleDeleteAddressDialog}
          deleteAddressDialog={deleteAddressDialog}
          selectedDeliveryLocationInfo={selectedDeliveryLocationInfo}
          handleDelete={handleDeleteDeliveryLocation}
        />
        <ClearCartDialog
          handleClose={handleClearCartDialog}
          clearCartDialog={clearCartDialog}
          handleDelete={handleClearAllCart}
        />
        <CartProcessingModal
          orderProcessing={orderProcessingDialog}
          handleClose={handleOrderProcessingDialog}
          message={modalMessage}
        />
        <SaveDialog
          saveDialog={saveDialog}
          handleClose={handleCloseSaveCartDialog}
          handleSave={handleSave}
          handleAction={handleOpenCartSavedDialog}
        />
        <CartSaved
          cartSaved={cartSaved}
          handleClose={handleCloseCartSavedDialog}
        />
        <SavedCartDialog
          savedCartDialog={savedCartDialog}
          handleClose={handleCloseSaveCartDialogView}
          cartOrder={cartOrder}
        />
        <NoSavedCart
          noSavedCart={noSavedCart}
          handleClose={handleCloseNoSavedCartDialog}
        />
      </div>
      <AddressConfirmDialog
        openDialog={addrsConfirmDialog}
        setOpenDialog={setAddrsConfirmDialog}
        options={['Cancel', 'Yes, Confirmed']}
        title="Address Confirmation!"
        desc="Please confirm your POS or store address to avoid mistakes."
        buttonAction={handlePlaceOrderProducts}
        loading={placeLoading}
      />
      <SuccessDialog
        openDialog={successDialog}
        setOpenDialog={setSuccessDialog}
        title="Thank You!"
        desc={`We sincerely appreciate your trust in OGApharmacy for your pharmaceutical needs. Your order #${newOrderId} has been received and is now being processed.`}
        option="Ok"
      />
    </MainContent>
  );
};

CartIndex.propTypes = {
  manualCart: PropTypes.bool,
};

CartIndex.defaultProps = {
  manualCart: false,
};

export default CartIndex;
