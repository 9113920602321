import React from 'react';
import {
  Grid, Typography, Paper
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { css } from '@emotion/react';

// the width in pixels for sm breakpoint.
const sm = '960px';

export const SuppliersGrid = styled(Grid)`
  display:flex;
  align-items: center;
`;

export const MoreSuppliers = styled(Typography)`
  font-size: 12px;
  color: #A8A8A8;
`;

export const SupplierGrid = styled(Grid)`
  margin-right: 15px;
  display: flex;
  margin-top: 12px;
  align-items: center;
`;

export const OrderItemPaper = styled(Paper)`
  padding: 30px;
  margin-bottom: 20px;
  border-radius: 10px;
  cursor: pointer;
  width: 89vw;
  border: 1px solid #fff;

  &:hover {
    border: 1px solid #FCED50;

    & #hide {
      display: none;
    }
  }

  @media(min-width: 992px) {
    padding: 20px 30px;
    margin-bottom: 20px;
    border-radius: 8px;
    width: 95vw;

    &:hover {
      & #hide {
        display: flex;
      }
    }
  }
`;
export const PaperCardGrid = styled(Grid)`
  height: 100%;
  flex-wrap: nowrap;
  align-items: center;
`;

export const ContentGridContainer = styled(Grid)`
  flex-wrap: nowrap;
  align-items: center;
`;

export const OrderItemImage = styled('img')`
  height: 100%;
  width: 100%;
  border-radius: 50%;
`;

export const ImageGridContainer = styled(Grid)`
  justify-content: flex-start;
  align-items: center;
  width: 129px;

  @media(max-width: ${sm}) {
    width: 70px;
    height: 70px;
  }
`;

export const TextGridContainer = styled(Grid)`
  margin-left: 5vw;
`;

export const MarkerGridContainer = styled(Grid)`
  display: none;
  opacity: .5;
  justify-content: flex-end;
  align-items: center;
  width: 35rem;

  ${'' /* @media(max-width: ${sm}) {
    width: 70px;
    height: 70px;
  } */}
`;

export const MarkerOrderImage = styled('img')`
  width: 2rem;

  @media(min-width: 992px) {
    width: .9rem;
  }
`;

export const SupplierImage = styled('img')`
  width: 1.5rem;

  @media(min-width: 992px) {
    width: 1.5rem;
  }
`;

export const SupplierText = styled(Typography)`
    font-size: 20px;
    font-weight: 600;
    margin-left: 7px;
    line-height: 1.5rem;

  @media(min-width: 992px) {
    font-size: 14px;
    margin-left: 10px;
  }
`;

export const AffiliteText = styled(Typography)`
    font-size: 20px;
    font-weight: 600;
    line-height: 1.5rem;

  @media(min-width: 992px) {
    font-size: 14px;
  }
`;

export const MarkerOrderText = styled(Typography)`
  font-size: 16px;
  font-weight: 500;
  margin-left: 20px;
`;

export const DatePlacedText = styled(Typography)`
  display: inline-block;
  font-size: 20px;
  margin-bottom: 15px;
  color: #656565;

  @media(min-width: 992px) {
    font-size: 14px;
    color: #A3A3A3;
  }
`;

const getColor = ({ status }) => {
  switch (status) {
    case 'O Out for Delivery':
      return css`
        color: #1C7CFF
      `;
    case 'O Sorted':
      return css`
        color: #F58634
      `;
    case 'O Delivered':
      return css`
        color: #00B050
      `;
    case 'O Canceled':
      return css`
        color: #EE0101
      `;
    default:
      return css`
        color: #FDC500
      `;
  }
};

export const OrderStatus = styled(({ status, ...otherProps }) => (
  <Typography {...otherProps} />
))`
  display: inline-block;
  font-size: 20px;
  font-weight: 700;

  @media(min-width: 992px) {
    font-size: 11px;
  }

  ${getColor}
`;

export const OrderNameText = styled(Typography)`
  font-size: 30px;
  margin-bottom: 30px;
  color: #424242;
  font-weight: 600;

  @media(min-width: 992px) {
    font-size: 20px;
    margin-bottom: 0;
  }
`;

export const OrderNumberText = styled(Typography)`
  font-size:  20px;
  margin-bottom: 30px;

  @media(min-width: 992px) {
    font-size: 14px;
    margin-bottom: 20px;
  }
`;

export const DeliveryDateText = styled(Typography)`
    font-size: 20px;
    font-weight: 600;
    color: #7D7D7D;

  @media(min-width: 992px) {
    font-size: 14px;
  }
`;

export const TotalText = styled(Typography)`
  font-size: 20px;
  margin-right: 7px;

  @media(min-width: 992px) {
    font-size: 16px;
    margin-right: 25px;
  }
`;

export const SavingsText = styled(Typography)`
  color: #3CC480;
  font-size: 20px;
  margin-left: 7px;

  @media(min-width: 992px) {
    font-size: 16px;
    margin-left: 25px;
  }
`;
