import { styled } from '@mui/material/styles';
import { Dialog, Typography, Box } from '@mui/material';

export const DialogContainer = styled(Dialog)`
  & .MuiDialog-paperWidthSm {
    max-width: 53rem;
    border-radius: 15px;
    padding: 20px 2rem;
  }
`;

export const Header = styled(Typography)`
  font-size: 2.3rem;
  font-weight: 700;
  color: #303030;
  margin-top: 2rem;
`;

export const Desc = styled(Typography)`
  padding-top: 1rem;
 font-size: 1.8rem;
 color: #606060;
 font-weight: 400;
 line-height: 60px;
`;

export const ImageWrapper = styled(Box)`
  background: #F3F9FF;
  border-radius: 40px 40px 0 0;
  width: 100%;
  height: 23rem;
  padding: 20px 0;
`;
