import { BottomNavigation, BottomNavigationAction } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledBottomNavigation = styled(BottomNavigation)`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #ffffff;
  box-shadow: -40px 40px 80px -8px #0000003D;
  height: 12rem;
  display: flex;
  justify-content: space-around;
  z-index: 10;
`;

export const DynamicAction = styled(BottomNavigationAction)`
  color: ${({ selected }) => (selected ? '#235A91' : '#606060')};
  font-weight:${({ selected }) => (selected ? '600' : '400')};
  & .MuiBottomNavigationAction-label {
    font-size: 2.3rem;
    white-space: nowrap;
    padding-top: 5px;
  }

  .MuiSvgIcon-root {
    color: ${({ selected }) => (selected ? '#235A91' : '#606060')};
    fill: ${({ selected }) => (selected ? '#235A91' : '#606060')};
    font-size: 4rem;
    font-weight: 400;


  }
`;
