import { styled } from '@mui/material/styles';
import { Grid, Typography } from '@mui/material';

export const CategoriesWrapper = styled('div')`
  height: 80px;
  box-shadow: 0px 6px 24px rgba(32, 30, 93, 0.08);
  padding: 15px 25px 15px 40px;
  position: fixed;
  z-index: 50;
  background-color: #fff;
  width: 100%;
  @media (max-width: 991px) {
    height: 140px;
    padding: 15px 1px 5px 40px;
    box-shadow: none;
    position: ${(props) => (props.productPage ? 'relative' : 'fixed')};
    margin-top: ${(props) => (props.productPage ? '12rem' : '0')};

}

`;
export const CategoryGrid = styled('div')`
    display: flex;
    padding: 0 2rem 0 .2rem;
    height: 100%;

    @media (min-width: 992px) {
      gap: 1rem;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    @media (max-width: 991px) {
      display: flex;
      overflow-x: auto;
      gap: 1.5rem;
      scrollBehavior: smooth;
      scrollbar-width: none;
      &::-webkit-scrollbar: {
        display: none;
        width: 0;
        height: 0;
        background: transparent;
      }
    }
`;

export const CardPaper = styled(Grid)`
  border-radius: 14.4559px;
  cursor: pointer;
  height: 100%;
  background: #FFFFFF;
  border: 0.602329px solid #E7E8E9;
  width: 12.5%;
  display: flex;
  justify-content: center;

    ${({ active }) => active && `
        border: 2px solid #235A91;
    `}

  @media (max-width: 991px) {
    border-radius: 2.7rem;
    min-width: 21rem;
    height: 100%;
    margin: 0px 5px;
    border: 1px solid #E7E8E9;
    justify-content: space-around;
    ${({ active }) => active && `
            border: 4px solid #235A91;
    `}

  }
`;

export const AllCategoriesPaper = styled(Grid)`
  border-radius: 14.4559px;
  cursor: pointer;
  height: 100%;
  background: #FFFFFF;
  border: 0.602329px solid #E7E8E9;
  width: 12.5%;
  display: flex;
  justify-content: center;

    ${({ active }) => active && `
        border: 1.5px solid #235A91;
    `}

  @media (min-width: 992px) {
  }

  @media (max-width: 991px) {
    border-radius: 1.5rem;
  }
`;

export const CategoryImg = styled('img')`
    display: block;
    @media (max-width: 991px) {
      width: 4rem;
      height: 4rem;
    }
`;

export const CardBox = styled('div')`
    padding: 5px 6px;
    display: flex;
    align-items: center;
    @media(max-width: 991px) {
      width: 100%;
      padding: 5px 20px;
    }
`;

export const CategoryText = styled(Typography)`
    font-size: .9em;
    text-align: left;
    padding-left: 5px;
    @media(max-width: 991px) {
      font-size: 1.8em;
      font-weight: 400;
      padding-left: 20px;
    }

`;

export const NextImage = styled('img')`
  width: 7rem;
  position: absolute;
  right: 1px;
  top: 12rem;
  z-index: 5;
  cursor: pointer;
  @media(min-width: 992px) {
    width: 5.5rem;
  }
  @media (max-width: 991px) {
    top: 2rem;
  }
`;
