import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Grid, useMediaQuery } from '@mui/material';
import CryptoJS from 'crypto-js';
import toast from 'react-hot-toast';
import { useMutation } from '@apollo/client';
import {
  DELETE_USER, GENERATE_TOKEN_MUTATION, LOGIN_MUTATION, SWITCH_ROLE_MUTATION
} from '../../mutations/auth';
import PasswordTextField from './passwordTextField';
import CustomButton from '../customComponents/customButton';
import {
  ForgotContainer, ForgotTypo, CircularProgressLoader,
  HaveAccountTypo, ForgotButton, HaveAccount, CustomField, InputWrapper, Label
} from './login.styles';
import { validateEmail, validatePassword } from './utils';
import { useStateValue } from '../../providers/stateProvider';
import UserActionTypes from '../../providers/reducers/user/userTypes';
import { OgaToken } from '../../graphql/token';
import { requestForToken } from '../../firebase/index';
import { goToModule } from '../shared/utils';
import ConfirmDialog from '../shared/confirmDialog';

const Login = () => {
  const [, dispatch] = Object.values(useStateValue());
  const initialState = {
    email: '',
    password: '',
    firebaseToken: '',
    validated: false,
    selected: 'email',
  };
  const initialErrorState = {
    emailError: false,
    mobileError: false,
    passwordError: false,
  };

  const [state, setState] = useState(initialState);
  const [errorState, setErrorState] = useState(initialErrorState);
  const [openModal, setOpenModal] = useState(false);

  const {
    email, password, firebaseToken, validated
  } = state;
  const { emailError, passwordError } = errorState;
  const [switchRole] = useMutation(SWITCH_ROLE_MUTATION);
  const [loginUser, { loading }] = useMutation(LOGIN_MUTATION);
  const [deleteUser] = useMutation(DELETE_USER);
  const [generateUserToken] = useMutation(GENERATE_TOKEN_MUTATION);

  const navigate = useNavigate();

  const validateState = (name, value) => {
    if (name === 'email') {
      return setErrorState({
        ...errorState,
        emailError: validateEmail(value)
      });
    }
    if (name === 'password') {
      return setErrorState({
        ...errorState,
        passwordError: validatePassword(value)
      });
    }
    return null;
  };

  React.useEffect(() => {
    if (!firebaseToken) {
      requestForToken()
        .then((token) => {
          setState({ ...state, firebaseToken: token });
        })
        .catch(() => {
          toast.error('An unexpected error occurred. Please try again later.');
        });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [firebaseToken]);

  React.useEffect(() => {
    setState({
      ...state,
      validated: !emailError && !passwordError && !!email && !!password
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [email, password]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    if (name === 'email' || name === 'password') {
      validateState(name, value);
      setState({ ...state, [name]: value });
    }
  };

  const controlAlert = (params) => {
    const { message, alertSeverity } = params;
    const msg = message || 'Module not available, coming soon...';
    if (alertSeverity === 'success') toast.success(msg);
    else toast.error(msg);
  };

  const redirectUser = (user, feedback, userPermissions) => {
    const { name } = user.businessuserSet[0].role;
    localStorage.setItem('oga_user_role', name);
    const moduleId = (name === 'oga-pharmacy-admin' || name === 'affiliate-admin') ? 'oga' : 'erp';
    if (feedback.feedbackRequired === 'true') {
      localStorage.setItem('feedback', JSON.stringify(feedback));
      return navigate('/feedback');
    }
    return goToModule(navigate, switchRole, userPermissions, controlAlert, moduleId);
  };

  const handleProceed = () => navigate('/business-setup', {
    state: {
      email, password, accountStatus: 'USER REGISTRATION NOT COMPLETE'
    }
  });

  const handleDelete = () => {
    deleteUser({
      variables: {
        email,
      }
    }).then((resp) => {
      const {
        message
      } = resp.data?.deleteUser ?? '';
      setState(initialState);
      toast.success(message);
      return setOpenModal(false);
    }).catch((err) => {
      toast.error(err?.message);
    });
  };

  const handleDeleteOldAffiliate = () => {
    generateUserToken({
      variables: {
        userEmail: email,
      }
    }).then((resp) => {
      const {
        token, restToken
      } = resp.data?.generateUserToken ?? '';
      localStorage.setItem('oga_auth_token', token);
      OgaToken.authToken = token;
      localStorage.setItem('oga_rest_token', restToken);
      handleDelete();
    }).catch((err) => {
      toast.error(err?.message);
    })
      .finally(() => {
        localStorage.removeItem('oga_auth_token');
        localStorage.removeItem('oga_rest_token');
      });
  };

  const handleEncrypt = (plainText, keyBase64, ivBase64) => {
    const key = CryptoJS.enc.Base64.parse(keyBase64);
    const iv = CryptoJS.enc.Base64.parse(ivBase64);
    const plainTextWordArray = CryptoJS.enc.Utf8.parse(plainText);

    const encrypted = CryptoJS.AES.encrypt(plainTextWordArray, key, {
      iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7
    });

    return encrypted.ciphertext.toString(CryptoJS.enc.Base64);
  };

  const handleSignIn = async (e) => {
    e.preventDefault();
    const secretKey = `${process.env.SECRET_KEY}`;
    const iv = `${process.env.SECRET_IV}`;

    const encryptedEmail = handleEncrypt(email, secretKey, iv);
    const encryptedPassword = handleEncrypt(password, secretKey, iv);
    loginUser({
      variables: {
        email: encryptedEmail,
        password: encryptedPassword,
        firebaseToken
      }
    }).then(({ data }) => {
      const {
        token, message, restToken, user, requireFeedback
      } = data?.loginUser || {};
      const { businessuserSet: [businessUser] } = user;
      const userPermissions = businessUser?.userPermissions || [];
      const role = businessUser.role.name;
      const { status, id } = businessUser.business;
      dispatch({
        type: UserActionTypes.UPDATE_USER,
        payload: {
          role,
          allPermissionsMap: userPermissions,
        }
      });
      localStorage.clear();
      localStorage.setItem('user_role', businessUser?.systemRole?.name || role);
      localStorage.setItem('affiliateStatus', status);
      localStorage.setItem('userId', id);
      OgaToken.authToken = token;
      OgaToken.restToken = restToken;
      localStorage.setItem('oga_auth_token', token);
      localStorage.setItem('oga_rest_token', restToken);
      localStorage.removeItem('receive_manual_order');

      toast.success(message);
      redirectUser(user, requireFeedback, userPermissions);
    }).catch((err) => {
      if (err?.message === 'USER REGISTRATION NOT COMPLETE') return setOpenModal(true);
      return toast.error(err?.message);
    });
  };

  const isLarge = useMediaQuery('(min-width: 992px)');

  return (
    <>
      <form onSubmit={handleSignIn}>
        <InputWrapper item container direction="column">
          <Grid item container justifyContent="space-between">
            <Label error={email ? emailError : false}>
              Email
              {' '}
              <span style={{ color: 'red' }}>*</span>
            </Label>
          </Grid>
          <CustomField
            variant="outlined"
            name="email"
            value={email}
            size={isLarge ? 'small' : 'medium'}
            type="text"
            required
            fullWidth
            error={email ? emailError : false}
            helperText={emailError ? 'Invalid email' : ' '}
            onChange={handleChange}
            InputProps={{ disableUnderline: true }}
            className="uat-register-mobile"
          />
        </InputWrapper>
        <PasswordTextField
          password={password}
          handleChange={handleChange}
          passwordError={passwordError}
          loading={loading}
        />
        <ForgotContainer item xs={12}>
          <ForgotButton>
            <ForgotTypo variant="caption">
              <Link to="/forgot-password">Forgot Password?</Link>
            </ForgotTypo>
          </ForgotButton>
        </ForgotContainer>
        <CustomButton
          data-testid="auth-button"
          disabled={!validated}
          type="submit"
          className="signin-button"
          id="signin-button"
          style={{ width: '100%', height: isLarge ? '3.5rem' : '5.7rem', fontSize: isLarge ? '' : '2.7rem' }}
        >
          {loading ? (
            <CircularProgressLoader
              disableShrink
              size={20}
              thickness={5}
            />
          ) : 'Sign In'}
        </CustomButton>
        <HaveAccount item xs={12}>
          <HaveAccountTypo variant="subtitle2" display="inline">
            Don&apos;t have an account?
            &nbsp;
            &nbsp;
            <Link to="/register" style={{ color: '#235A91', textDecoration: 'underline' }}>Sign Up</Link>
          </HaveAccountTypo>
        </HaveAccount>
      </form>
      <ConfirmDialog
        openDialog={openModal}
        setOpenDialog={setOpenModal}
        title="Registration Not Completed!"
        desc="Would you like to continue?"
        options={['Cancel', 'Yes, Continue']}
        buttonAction={handleProceed}
        handleDelete={handleDeleteOldAffiliate}
      />
    </>
  );
};

Login.propTypes = {
};

Login.defaultProps = {
};

export default Login;
