import {
  Typography, Checkbox, InputBase, FormControl,
  MenuItem
} from '@mui/material';
import { styled } from '@mui/material/styles';

export const FormField = styled(FormControl)`
  & .MuiFilledInput-root {
    border: 1px solid #E7E8E9;
    background-color:  #FFF;
    border-radius: 15px;
    @media(max-width: 991px) {
      font-size: 2rem;
      padding: 1rem;
      border: 1.5px solid #E7E8E9;

    }
  }
  & .MuiFilledInput-underline:before {
    border-bottom: none;
  }
  & .MuiSelect-filled.MuiSelect-filled {
    text-align: initial;
  }
  & .MuiInputBase-input.Mui-disabled {
    color: #000000;
  }
  & .MuiFormLabel-root.Mui-disabled {
    color: #000000;
  }
  & .MuiInputLabel-filled.MuiInputLabel-shrink.MuiInputLabel-marginDense{
    @media(max-width: 991px) {
      font-size: 2rem;
    }
  }
  & .MuiInputLabel-root{
    font-size: 1rem;
    @media(max-width: 991px) {
      font-size: 2.2rem;
    }
  }
  & .MuiSelect-icon {

     @media(max-width: 991px) {
       width: 3rem;
      height: 3rem;
    }
  }
`;

export const FieldCheckbox = styled(Checkbox)`
  color: #FCED50;
  padding: 6px;
`;

export const SelectNameTypo = styled(Typography)`
  font-size: 1rem;
  @media (max-width: 991px) {
    font-size: 2rem;
  }
`;

export const CreditNameTypo = styled(Typography)`
  font-size: .7rem;
`;

export const CreditDaysInput = styled(InputBase)`
  font-size: .7rem;
`;

export const CheckboxImage = styled('img')`
  width: 17px;
`;

export const CSMenuItem = styled(MenuItem)`
  display: block;
  padding: 1rem 2rem;
  background-color: #ffffff;
  max-height: 32.563rem;
`;

export const LinkAddrsTitle = styled(Typography)`
  font-weight: 700;
  font-size: 1rem;
  color: #000000;

  @media (max-width: 991px) {
    font-size: 2rem;
  }
`;

export const LinkSubAddrsTitle = styled(Typography)`
  font-weight: 500;
  font-size: .875rem;
  color: #606060;
  margin-top: .5rem;
  margin-bottom: 1.5rem;
  @media (max-width: 991px) {
    font-size: 1.5rem;
  }
`;

export const LocationImg = styled('img')`
  width: 2.75rem;
  height: 2.75rem;
  @media (max-width: 991px) {
    width: 3.75rem;
  height: 3.75rem;
  }
`;
