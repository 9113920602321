import React, { useState } from 'react';
import {
  Grid, Typography, IconButton, Checkbox,
  TableContainer, Table, TableHead, TableCell
} from '@mui/material';

import AddIcon from '@mui/icons-material/Add';
import { styled } from '@mui/material/styles';
import { css } from '@emotion/react';

const PaginationText = styled(Typography)`
  font-size: 25px;
  color: #4D4F5C;
  white-space: nowrap;

  @media(min-width: 992px) {
    font-size: 16px;
  }
`;

export default ({
  from, to, total, noun
}) => (
  <PaginationText>
    Showing
    <b>
      {' '}
      {from}
      -
      {to}
      {' '}
    </b>
    of
    <b>
      {' '}
      {total}
      {' '}
    </b>
    {noun}
  </PaginationText>
);
