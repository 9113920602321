import React from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import toast from 'react-hot-toast';
import { Box, Grid } from '@mui/material';
import {
  ProductCardsContainer, ProductCardGrid, NoResultsText,
  FooterGrid,
  NoResultsContainer,
  NoResultsHeader,
  MiddleImage,
} from './newOrder.styles';
import ProductCard from './productCard';
import { CLEARANCE_ORDER_PRODUCT, NEW_ORDER_PRODUCTS_PAGE_QUERY, NEW_ORDER_PRODUCTS_LIST_PAGE_QUERY } from '../../../queries/products';
import NewOrdersLoader from '../../customComponents/loaders/newOrdersLoader';
import TablePagination from '../../shared/tablePagination';
import useSearchParamsState from '../../shared/helpers/ulrSearchParams';

const NewOrder = ({
  state,
  productId,
  active,
  banner,
  displayType
}) => {
  const {
    searchValue, priceMin,
    priceMax, therapeuticTypeIds, availabilityStatus, manufacturer
  } = state;
  const { url: bannerUrl, banner: bannerImage } = banner[0] || {};

  const handleAlertOpen = (msg) => toast(msg);
  const [pageCount, setPageCount] = useSearchParamsState('pc', '20');
  const [pageNumber, setPageNumber] = useSearchParamsState('pn', '1');

  const handleBannerClick = () => bannerUrl && window.open(bannerUrl);

  const ogaOrderingForAffiliateKey = 'oga_ordering_for_affiliate';
  const selectedAffiliate = localStorage.getItem(ogaOrderingForAffiliateKey);
  const _selectedAffiliate = selectedAffiliate ? JSON.parse(selectedAffiliate) : null;
  const variables = {
    search: searchValue,
    therapeuticTypeIds,
    manufacturer,
    priceMin: priceMin ? parseFloat(priceMin) : null,
    priceMax: priceMax ? parseFloat(priceMax) : null,
    pageCount: Number(pageCount),
    pageNumber: Number(pageNumber),
    availabilityStatus: (availabilityStatus === 'readilyAvailable') ? 'true' : (availabilityStatus === 'onRequest') ? 'false' : '',
    productId,
    topSelling: active === 'topSellingProducts',
    affiliateId: selectedAffiliate && _selectedAffiliate?.id
  };

  const productQuery = displayType === 'list' ? NEW_ORDER_PRODUCTS_LIST_PAGE_QUERY : NEW_ORDER_PRODUCTS_PAGE_QUERY;

  const { loading, data, refetch } = useQuery(productQuery, {
    fetchPolicy: 'no-cache',
    variables,
    skip: active === 'clearanceSale'
  });

  const { data: clearanceData, loading: clearanceLoading } = useQuery(
    CLEARANCE_ORDER_PRODUCT,
    {
      fetchPolicy: 'no-cache',
      variables,
      skip: active !== 'clearanceSale'
    }
  );

  // useEffect(() => {
  //   if (active === 'clearanceSale') {
  //     getClearanceProducts();
  //   }
  // }, [active]);

  const { newOrderPageProducts: products = [], newOrderPageProductsTotalNumber = 0 } = data || {};

  const { clearanceOrderPageProducts: clearanceProducts = {}, clearanceOrderPageProductsTotalNumber = 0 } = clearanceData || {};

  const productsData = active === 'clearanceSale' ? clearanceProducts : products;
  const productTotalNumber = active === 'clearanceSale' ? clearanceOrderPageProductsTotalNumber : newOrderPageProductsTotalNumber;
  const dataLoading = active === 'clearanceSale' ? clearanceLoading : loading;

  return (
    dataLoading ? <NewOrdersLoader />
      : (
        <Box style={{ marginTop: '1rem' }}>
          <ProductCardsContainer container spacing={3}>
            {productsData.length
              ? productsData.map((product, index) => (
                <>
                  <ProductCardGrid key={product.id} item lg={3} md={displayType !== 'list' ? 6 : 12} xs={displayType !== 'list' ? 6 : 12}>
                    <ProductCard
                      product={product}
                      openAlert={handleAlertOpen}
                      index={index}
                      refetch={refetch}
                      active={active}
                      displayType={displayType}
                    />
                  </ProductCardGrid>
                  {bannerImage && (
                    (productsData.length === 10 && index === 5) || (productsData.length === 20 && index === 11) ? (
                      <Grid item xs={12} container justifyContent="center" style={{ marginBottom: '1rem' }}>
                        <MiddleImage onClick={handleBannerClick} src={bannerImage} alt="Banner" />
                      </Grid>
                    ) : ''
                  )}
                </>
              )) : (
                <NoResultsContainer>
                  <NoResultsHeader>
                    Products Not Found!
                  </NoResultsHeader>
                  <NoResultsText>
                    Please we currently don’t have the product you searched for,
                    check out our recommended products below
                  </NoResultsText>
                </NoResultsContainer>
              )}
          </ProductCardsContainer>
          <FooterGrid item container className="joy-new-order-pagination">
            <TablePagination
              total={productTotalNumber}
              pageCount={+pageCount}
              setPageCount={setPageCount}
              pageNumber={+pageNumber}
              setPageNumber={setPageNumber}
              hideRowsPerPage
              displayShowResult
            />
          </FooterGrid>
        </Box>
      )
  );
};

NewOrder.propTypes = {
  state: PropTypes.instanceOf(Object),
  productId: PropTypes.number,
  active: PropTypes.string,
  banner: PropTypes.instanceOf(Array),
  displayType: PropTypes.string
};

NewOrder.defaultProps = {
  state: {},
  productId: 0,
  active: '',
  banner: [],
  displayType: ''
};

export default NewOrder;
