import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ShoppingBagIcon from '@mui/icons-material/ShoppingBag';
import ShoppingBagOutlinedIcon from '@mui/icons-material/ShoppingBagOutlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';
import PersonIcon from '@mui/icons-material/Person';
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import { DynamicAction, StyledBottomNavigation } from './mobileFooter.styles';
import { TopSelling, FiiledTopSelling } from '../../../../assets/svgs';
import { useStateValue } from '../../../../providers/stateProvider';

const MobileFooter = ({ active, handleTabChange }) => {
  const [value, setValue] = useState(0);
  const [
    { user: { role } }
  ] = Object.values(useStateValue());
  const handleChange = (newValue) => {
    setValue(newValue);
    if (newValue === 0) {
      handleTabChange('all');
    } else if (newValue === 1) {
      handleTabChange('topSellingProducts');
    } else if (newValue === 2) {
      handleTabChange('clearanceSale');
    } else if (newValue === 3) {
      handleTabChange('profile');
    }
  };

  useEffect(() => {
    if (active === 'all') {
      setValue(0);
    } else if (active === 'topSellingProducts') {
      setValue(1);
    } else if (active === 'clearanceSale') {
      setValue(2);
    } else if (active === 'profile') {
      setValue(3);
    }
  }, [active]);

  return (
    <StyledBottomNavigation
      value={value}
      onChange={(event, newValue) => handleChange(newValue)}
      showLabels
    >
      <DynamicAction
        label="Buy"
        icon={value === 0 ? <ShoppingBagIcon /> : <ShoppingBagOutlinedIcon />}
        selected={value === 0}
      />
      <DynamicAction
        label="Top Selling"
        icon={value === 1 ? <FiiledTopSelling /> : <TopSelling />}
        selected={value === 1}
      />
      <DynamicAction
        label="Clearance"
        icon={value === 2 ? <AccessTimeFilledIcon /> : <AccessTimeOutlinedIcon />}
        selected={value === 2}
      />
      {role === 'affiliate-admin' && (
        <DynamicAction
          label="Profile"
          icon={value === 3 ? <PersonIcon /> : <PersonOutlineOutlinedIcon />}
          selected={value === 3}
        />
      )}

    </StyledBottomNavigation>
  );
};

MobileFooter.propTypes = {
  active: PropTypes.string.isRequired,
  handleTabChange: PropTypes.func.isRequired,
};

export default MobileFooter;
