import React, { useState, useEffect } from 'react';
import {
  Grid, Paper, Table, TableBody, TableHead, Typography
} from '@mui/material';
import { KeyboardArrowLeft } from '@mui/icons-material';
import { useParams, useNavigate } from 'react-router-dom';
import { useQuery, useMutation } from '@apollo/client';
import toast from 'react-hot-toast';
import { PROMOTION_QUERY, PROMOTION_PRODUCTS_QUERY } from '../../../queries/promotions';
import { REMOVE_PROMOTION_PRODUCTS_MUTATION, COMPLETE_PROMOTION_MUTATION } from '../../../mutations/promotions';
import MainContent from '../../customComponents/mainContent';
import ProductsTableLoader from '../../customComponents/loaders/productsTableLoader';
import { TableHeader } from '../../shared/tableGlobalStyle.styles';
import CustomButton from '../../customComponents/customButton';
import CustomSearchField from '../../shared/CustomSearchField';
import NotificationDialog from '../../shared/notificationDialog';
import SimpleBadge from '../../shared/simpleBadge';
import TablePagination from '../../shared/tablePagination';
import PromoHeader from '../promoHeader';
import ReturnRow from './returnRow';
import {
  GridContainer, TitleTextGridContainer, PrimaryTitle, TitleGrid, MenuButton,
  PaperWrapper, TableContainerWrapper, CustomCheckbox, DivFullWidth, SearchGrid,
  ButtonWrapper, TSCustomButton, TCustomButton, CircularProgressLoader
} from './promoReview.styles';
import { Product } from '../../../providers/reducers/product/classes/Product';

import { useStateValue } from '../../../providers/stateProvider';

const headers = [
  'Product Name', 'Pack Size', 'Cost Price', 'Selling Price', 'Promo Price',
];

const PromoReview = () => {
  const [searchText, setSearchText] = useState('');
  const [pageCount, setPageCount] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);
  const [selected, setSelected] = useState([]);
  const [stateRows, setStateRows] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [buttonAction, setButtonAction] = useState({});

  const [{ user }] = Object.values(useStateValue());
  const { platform } = user;

  const navigate = useNavigate();
  const { id: promoId, name: nameParam } = useParams();

  const {
    loading: promoLoading, data: promotionData
  } = useQuery(PROMOTION_QUERY, {
    fetchPolicy: 'no-cache',
    variables: { id: promoId }
  });
  const { promotion } = promotionData ?? {};

  const [completePromotion, { loading: createLoading }] = useMutation(COMPLETE_PROMOTION_MUTATION);
  const handlePromoButton = () => {
    if (!nameParam) {
      completePromotion({
        variables: {
          promotionId: promoId,
        }
      }).then(() => {
        setButtonAction({ title: 'Product Created', desc: 'Your promotion has been successfully created.' });
        setOpenDialog(true);
      }).catch((e) => {
        toast.error(e?.message);
      });
    } else {
      navigate('/promotions');
    }
  };

  const handleSelectAll = (event) => {
    if (event?.target?.checked) {
      const newSelections = stateRows.map((row) => row.product.id);
      return setSelected(newSelections);
    }
    return setSelected([]);
  };

  const handleSelect = (_, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const renderCheckbox = () => (
    <CustomCheckbox
      size="small"
      checked={selected.length === stateRows.length}
      onChange={handleSelectAll}
      inputProps={{ 'aria-label': 'select product' }}
    />
  );

  const returnHeaders = () => headers.map((header) => (
    <TableHeader>{header}</TableHeader>
  ));

  const variables = {
    promotionId: promoId,
    ...(searchText.length > 2 && { searchProductName: searchText }),
    pageCount,
    pageNumber,
  };

  const {
    loading, error, data, refetch
  } = useQuery(PROMOTION_PRODUCTS_QUERY, {
    fetchPolicy: 'no-cache',
    variables
  });

  const [removePromotionProducts] = useMutation(REMOVE_PROMOTION_PRODUCTS_MUTATION);
  const handleRemoveSelected = () => {
    removePromotionProducts({
      variables: {
        promotionId: promoId,
        productIds: selected,
      }
    }).then(({ data: RemoveData }) => {
      const { message } = RemoveData?.removePromotionProducts || {};
      setButtonAction({ title: 'Product Removed', desc: 'Your selected product has been removed successfully' });
      setOpenDialog(true);
      toast.success(message);
      handleSelectAll();
      refetch();
    }).catch((e) => {
      toast.error(e?.message);
    });
  };

  useEffect(() => {
    if (data && data.promotionProducts) {
      const { promotionProducts } = data;
      setStateRows(promotionProducts);
    }
  }, [data]);

  if (error) return <div>{error.message}</div>;
  const {
    promotionProducts = [], promotionProductsTotalNumber = 0
  } = data || {};

  const rows = promotionProducts?.map((promoProd) => ({
    ...new Product(promoProd?.product, platform),
    promoPrice: promoProd?.promoPrice
  }));

  const handleButtonAction = (type) => {
    if (type === 'removeProduct') {
      setButtonAction({ title: 'Remove Product?', desc: 'Are you sure you want to remove product' });
    } else {
      setButtonAction({ title: 'Promo Created', desc: 'Your promotion has been successfully created' });
    }
    setOpenDialog(!openDialog);
  };

  return (
    <MainContent>
      <GridContainer container>
        <TitleGrid style={{ marginBottom: '1rem', display: 'flex', width: '1%' }} onClick={navigate}>
          <MenuButton onClick={navigate}>
            <KeyboardArrowLeft style={{ fontSize: '1.8rem', fill: 'rgb(35, 90, 145)' }} />
          </MenuButton>
          <Typography style={{ fontWeight: '500', fontSize: '14px', color: '#235A91' }}>back</Typography>
        </TitleGrid>
        <TitleGrid container item>
          <TitleTextGridContainer item xs={12} md={6}>
            <PrimaryTitle variant="h5">{promotion?.name}</PrimaryTitle>
          </TitleTextGridContainer>
          <Grid item container alignItems="center" justifyContent="flex-end" xs={12} md={6}>
            <CustomButton
              onClick={handlePromoButton}
              type="tertiary"
              header
              style={{
                width: '11.125rem', height: '3rem', background: '#235A91', boxShadow: '0px 4px 14px rgba(151, 151, 151, 0.19)',
                borderRadius: '5px', color: '#ffffff', fontWeight: '700', fontSize: '12px'
              }}
            >
              {createLoading ? (
                <CircularProgressLoader
                  disableShrink
                  size={22}
                  thickness={5}
                />
              ) : nameParam ? 'Done' : 'Create promo'}
            </CustomButton>
          </Grid>
        </TitleGrid>
        <PromoHeader
          promotion={promotion}
          loading={promoLoading}
        />
        <PaperWrapper elevation={0}>
          <SearchGrid item container>
            <CustomSearchField
              name="search"
              value={searchText}
              placeholder="Search"
              handleChange={(e) => setSearchText(e?.target?.value)}
              style={{ width: '74%' }}
              categoriesList
            />
            <ButtonWrapper item container>
              <SimpleBadge value={selected && selected.length}>
                <TSCustomButton
                  type="secondary"
                  header
                  disabled={!selected.length}
                  onClick={() => handleButtonAction('removeProduct')}
                >
                  Remove Selected
                </TSCustomButton>
              </SimpleBadge>
            </ButtonWrapper>
            <ButtonWrapper item container>
              <TCustomButton
                type="tertiary"
                header
                onClick={() => navigate(`/promotions/products/${promoId}/${nameParam || ''}`)}
              >
                Add Products
              </TCustomButton>
            </ButtonWrapper>
          </SearchGrid>
          <TableContainerWrapper component={Paper}>
            {loading ? <ProductsTableLoader /> : (
              <Table>
                <TableHead>
                  <TableHeader>{renderCheckbox()}</TableHeader>
                  {returnHeaders()}
                </TableHead>
                <TableBody style={{ width: '100%' }}>
                  {rows.map((_row, indx) => (
                    <ReturnRow
                      key={_row.id}
                      row={_row}
                      rowIndx={indx}
                      selected={selected}
                      handleSelect={handleSelect}
                    />
                  ))}
                </TableBody>
              </Table>
            )}
          </TableContainerWrapper>
          <DivFullWidth>
            {promotionProductsTotalNumber > 0 && (
            <TablePagination
              total={promotionProductsTotalNumber}
              pageCount={pageCount}
              setPageCount={setPageCount}
              pageNumber={pageNumber}
              setPageNumber={setPageNumber}
            />
            )}
          </DivFullWidth>
        </PaperWrapper>
        <NotificationDialog
          openDialog={openDialog}
          setOpenDialog={setOpenDialog}
          action2={buttonAction.title === 'Remove Product?' && 'Yes, Remove Product'}
          action2Func={buttonAction.title === 'Remove Product?' && handleRemoveSelected}
          action={buttonAction.title === 'Remove Product?' ? 'Cancel' : 'Ok'}
          actionFunc={(buttonAction.title === 'Remove Product?' || buttonAction.title === 'Product Removed') ? setOpenDialog : () => navigate('/promotions')}
          title={buttonAction.title}
          desc={buttonAction.desc}
          type={buttonAction.title === 'Remove Product?' && 'warning'}
        />
      </GridContainer>
    </MainContent>
  );
};

export default PromoReview;
