import {
  Grid,
  TableCell
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { css } from '@emotion/react';

export const MainTableRow = styled(Grid)`
  width: 70rem;
  align-items: center;

  & > * {
    border-bottom: unset;
  }
  :nth-child(2n) {
    background: #F4F5F6;
  }
`;

export const BodyCell = styled(TableCell)`
  text-transform: capitalize;
  font-size: 1.2rem;
  line-height: 2.8;
  padding: 10px;

  @media(min-width: 992px) {
    font-size: .9rem;

    &:nth-child(1) {
      width: 26%;
    }
    &:nth-child(2), &:nth-child(3), :nth-child(4), :nth-child(5) {
      width: 12%;
    }
    :nth-child(6), :nth-child(7) {
      width: 9%;
    }
    &:nth-child(8) {
      width: 8%;
    }
  }
`;

const SoldOutStyles = css`
  background-color: #FBF777;
  color: #606060;
`;

const activeStyles = css`
  background-color: #DCFEDD;
  color: #07DE10;
`;

const inactiveStyles = css`
  background-color: #FBDADA;
  color: #FA3636;
`;

const getStyles = ({ status }) => {
  switch (status) {
    case 'active':
      return activeStyles;
    case 'inactive':
      return inactiveStyles;
    default:
      return SoldOutStyles;
  }
};

export const ColorGrid = styled(Grid)`
  border-radius: 7px;
  text-transform: capitalize;
  font-size: .85rem;
  font-weight: 500;
  justify-content: center;
  padding: 1px 0;

  ${getStyles}
`;
