import React from 'react';
import {
  Grid, Typography, IconButton, Paper,
  TableContainer, Table, TableHead, TableCell, TableRow
} from '@mui/material';
import {
  KeyboardArrowLeft, KeyboardArrowRight,
} from '@mui/icons-material';

import { styled } from '@mui/material/styles';
import { css } from '@emotion/react';

// the width in pixels for sm breakpoint.
const sm = '960px';

export const MainContainer = styled(Grid)`
  background: #F0F0F0;
`;

export const MainTableHead = styled(TableHead)`
  display: none;

  @media(min-width: 992px) {
    display: table-header-group;
  }
`;

export const ActiveOrderNumber = styled(Typography)`
  font-size: 13x;
  color: #424242;
  font-weight: 600;
  padding: 20px 0px;

  @media(min-width: 992px) {
    font-size: 11px;
    padding: 10px 0px;
  }
`;

const getStyles = ({ show }) => {
  if (show) {
    return css`
      display: inline-flex;
    `;
  }
  return css`
    display: none;
  `;
};

export const ArrowButton = styled(({ ...otherProps }) => (
  <IconButton {...otherProps} />
))`
  padding: 0;
  margin-top: 14px;

  @media(min-width: 992px) {
    ${getStyles}
  }
`;

export const ArrowLeftIcon = styled(KeyboardArrowLeft)`
  cursor: pointer;
  font-size: 3rem;

  @media(min-width: 992px) {
    font-size: 1.9rem;
  }
`;

export const ArrowRightIcon = styled(KeyboardArrowRight)`
  cursor: pointer;
  font-size: 3rem;

  @media(min-width: 992px) {
    font-size: 1.9rem;
  }
`;

export const OrderItemsGrid = styled(Grid)`
  margin-bottom: 30px;

  @media(max-width: ${sm}) {
    margin-bottom: 10px;
  }
`;

export const AddProductCard = styled(Grid)`
  @media (max-width: 991px) {
    width: 100%;
  }
`;

export const AddProductCardSection = styled(Paper)`
  padding: 0 30px 30px 30px;

  @media (max-width: 991px) {
    padding: 0 2rem 3rem;
    width: 100%;
  }
`;

export const TableContainerWrapper = styled(TableContainer)`
  padding-top: 30px;
  margin-top: 30px;
`;

export const TableComponent = styled(Table)`
  min-width: 650;
`;

export const HeaderCell = styled(TableCell)`
  font-size: 20px;
  font-weight: 600;
  padding-right: 10px;
  color: #A3A3A3;
  width: 20%;

  @media(min-width: 992px) {

  }
`;

export const ProductName = styled(TableRow)`
  font-size: 30px;
  width: 80%;
  background-color: #424242;

`;

export const CartItemButton = styled('span')`
    vertical-align: middle;
    cursor: pointer;
    padding: 4px 20px;
    font-size: 30px;
    backgroung-color: #FFF;
    border: 0.1px solid #F0F0F0;

    &:hover {
      background-color: #fafafa;
    }

`;

export const CartItemButtonContainer = styled('div')`
  margin-top: 0;
  text-align: center;
`;
