import React from 'react';
import {
  Grid, Typography, IconButton
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

import { styled } from '@mui/material/styles';
import { css } from '@emotion/react';

export const TitleGridContainer = styled(Grid)`
  justify-content: space-between;
  align-items: flex-end;
`;

export const TitleTextGridContainer = styled(Grid)`
  flex-direction: column;
`;

export const ButtonsGrid = styled(Grid)`
  display: flex;
`;

export const PrimaryTitle = styled(Typography)`
  color: #424242;
  font-size: 35px;
  font-weight: 500;

  @media(min-width: 992px) {
    font-size: 30px;
    font-weight: 600;
  }
`;

export const SecondaryTitle = styled(Typography)`
  font-size: 25px;
  color: #424242;

  @media(min-width: 992px) {
    font-size: 16px;
  }
`;

const primaryStyles = css`
  background-color: #FCED50;
  color: #424242;

  &:hover {
    background-color: #D2C647;
  }
`;

const secondaryStyles = css`
  background-color: none;
  color: #424242;
  border: 1px solid black;
  margin-right: 2rem;
`;

const getButtonStyles = ({ type }) => {
  switch (type) {
    case 'secondary':
      return secondaryStyles;
    default:
      return primaryStyles;
  }
};

export const OrderButton = styled(({ color, ...otherProps }) => (
  <IconButton {...otherProps} classes={{ disabled: 'disabled', label: 'label' }} />
))`
  height: 70px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 25px;
  border-radius: 10px;

  &.disabled {
    color: #848483;
    border: 1px solid #CCCCCB;
  }

  @media(min-width: 992px) {
    height: 50px;
    padding: 0px 25px;
    border-radius: 10px;
  }

  ${getButtonStyles}
`;

export const PlusIcon = styled(AddIcon)`
  font-size: 1.5rem;

  @media(max-width: 992px) {
    font-size: 1.5rem;
  }
`;

export const OrderButtonText = styled(Typography)`
  font-size: 27px;
  font-weight: 500;
  margin-left: 5px;

  @media(min-width: 992px) {
    font-size: 16px;
    margin-left: 20px;
  }
`;
