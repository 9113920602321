import { ApolloClient } from '@apollo/client/core';
import { HttpLink, InMemoryCache, split } from '@apollo/client';
import { WebSocketLink } from '@apollo/client/link/ws';
import { getMainDefinition } from '@apollo/client/utilities';
import { setContext } from 'apollo-link-context';
import { OgaToken } from './token';

const API_HOST = OgaToken.APP_LINK;
const WS_HOST = OgaToken.WS_URL;

const getApolloClient = async () => {
  const authLink = setContext((_, { headers }) => {
    // get the authentication token from local storage if it exists
    const token = localStorage.getItem('oga_auth_token') || OgaToken.authToken;

    // return the headers to the context so httpLink can read them
    return {
      headers: {
        ...headers,
        authorization: token ? `JWT ${token}` : '',
      }
    };
  });

  const httpLink = new HttpLink({
    uri: API_HOST,
  });

  const wsLink = new WebSocketLink({
    uri: WS_HOST,
    options: {
      reconnect: true, // Reconnect if the connection drops
      connectionParams: {
        // include additional connection parameters if needed
        // e.g., authentication tokens
        // authToken: userToken
      }
    }
  });

  const link = split(
    ({ query }) => {
      const { kind, operation } = getMainDefinition(query);
      // Return true if this is a subscription operation
      return kind === 'OperationDefinition' && operation === 'subscription';
    },
    wsLink, // Use the WebSocket link for subscriptions
    authLink.concat(httpLink) // Use the HTTP link for queries and mutations, but after setting the auth header
  );

  return new ApolloClient({
    link,
    cache: new InMemoryCache()
  });
};

export default getApolloClient;
