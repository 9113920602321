import React, { useState, useEffect } from 'react';
import Proptypes from 'prop-types';
import Fade from '@mui/material/Fade';
import toast from 'react-hot-toast';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Tooltip,
  DialogActions,
  DialogContentText, Checkbox, useMediaQuery
} from '@mui/material';
import Papa from 'papaparse';
import { useNavigate } from 'react-router-dom';
import UploadFileBar from '../shared/fileBarUpload';

import DropZone from './supplierCsvDropzone';
import {
  UploadFileWrapper
} from './suppliercsvDialog.styles';
import {
  CsvDialogHeader,
  CsvDialogBody,
  TableRow,
} from './csvErrorDialog.styles';
import { useStateValue } from '../../providers/stateProvider';
import ReturnSelectField from '../suppliers/individual/returnSelectField';
import {
  SecondaryTitle,
} from '../orders/newOrder/newOrderContainer.styles';
import {
  MainMapGrid, Ul, Li, CSVNoIconButtonText, CDialog, MapGrid,
  CSVNoIconCancelButton, CSVNoIconUploadButton, DownloadIconImage, DownloadIconWrapper,
  MapInfoGrid, TextWrapper, PrimaryTitle, SelectGrid, ModifyGrid, ModifyCSVLabel, ButtonWrapper
} from './mapSuppliercsvDialog.styles';
import DownloadIcon from '../../assets/images/supplier/download.png';
import { toTitleCase } from '../../utils/toTitleCase';
import { getAxios } from '../../axios';
import { CsvFilledCustomButton, CsvOutlinedCustomButton } from '../customComponents/customButton';
import { OgaToken } from '../../graphql/token';
import { handleDownloadUtil } from '../../utils/utils';

const Transition = React.forwardRef((props, ref) => (
  <Fade ref={ref} {...props} />
));

const MapCsvDialog = ({
  fields, model, endpoint, backRoute, experimentalFields,
  setData
}) => {
  const isSmall = useMediaQuery('(max-width: 991px)');
  const [{
    user: { businessId, businessUserId, role: userRole }
  }] = Object.values(useStateValue());
  const navigate = useNavigate();
  const axios = getAxios();
  const initialProgressState = {
    width: 0,
    state: 'ok'
  };
  const noData = 'No Data';
  const role = localStorage.getItem('oga_user_role');
  const [files, setFiles] = useState({});
  const [mapCsv, setMapCsv] = useState({
    headers: new Map(),
    mapping: new Map(fields),
    rows: [],
    duplicates: 0,
    showMapModal: false,
    showActionRequestModal: false,
    showExperimentalFields: false
  });

  const [loading, setLoading] = useState(false);
  const [, setLoaded] = useState(false);
  const [uploadBox, setUploadBox] = useState(true);
  const [progressState, setprogressState] = useState(initialProgressState);
  const [csvData, setCsvData] = useState([]);
  const [csvErrors, setCsvErrors] = useState([]);
  const [csvHeader, setCsvHeader] = useState([]);
  const [successPopper, SetSuccessPopper] = useState(false);

  const [{ cart: { isManualOrder } }] = Object.values(useStateValue());
  const {
    headers, mapping, rows, duplicates, showMapModal, showActionRequestModal
  } = mapCsv;

  const lowerCaseModel = model.toLowerCase();


  const token = localStorage.getItem('oga_rest_token');

  const downloadD = async () => {
    try {
      await handleDownloadUtil(OgaToken.SERVER_LINK, 'export_order_sheet', token);
    } catch (err) {
      toast.error('Export download failed');
      console.error('download failed:', err);
    }
  };

  const getTemplate = async () => {
    switch (toTitleCase(model)) {
      case 'Product':
        return window.open('https://res.cloudinary.com/health-id/raw/upload/v1697455719/products_upload.csv');
      case 'Categories':
        return window.open('https://res.cloudinary.com/health-id/raw/upload/v1694444303/categoriesUPDATED.csv');
      case 'Order':
        return downloadD();
      default:
        return window.open('https://res.cloudinary.com/health-id/raw/upload/v1708508791/batch_upload_sample.csv');
    }
  };

  const getHeadersToArray = () => {
    const headersToArray = [];
    headers.forEach((value, key) => {
      headersToArray.push(key);
    });
    return headersToArray;
  };
  const closeDialog = () => {
    setMapCsv({
      ...mapCsv, showMapModal: false, showActionRequestModal: false, duplicates: 0, headers: new Map()
    });
    setFiles({});
    setUploadBox(true);
    setprogressState(initialProgressState);
  };
  const closeNoticeDialog = () => {
    closeDialog();
  };
  const closeCsvRuleDialog = () => {
    SetSuccessPopper(false);
    setMapCsv({ ...mapCsv, showMapModal: true });
  };
  const modifyCSV = () => {
    setMapCsv({ ...mapCsv, showMapModal: true });
  };
  const foundDuplicates = () => {
    const getUniqueHeaderIndexes = () => {
      const uniqueHeaderIndexes = [];
      const headersToArray = getHeadersToArray();
      mapping.forEach((value) => {
        if (value.unique) {
          const location = headersToArray.filter((header) => header.trim() !== '')
            .findIndex((item) => item === value.value);
          if (location > -1) {
            uniqueHeaderIndexes.push(location);
          }
        }
      });
      return uniqueHeaderIndexes;
    };
    const uniqueKeyGenerator = (row) => {
      let uniqueKey = '';
      getUniqueHeaderIndexes().forEach((field) => {
        if (field <= row.length - 1) {
          uniqueKey += row[field];
        } else {
          // possible incomplete rows
        }
      });
      return uniqueKey;
    };
    const uniqueMap = new Map();
    let dup = 0;
    rows.forEach((row, index) => {
      if (index > 0) {
        const uniqueKey = uniqueKeyGenerator(row);
        const existingKey = uniqueMap.get(uniqueKey);
        if (existingKey) {
          dup++;
        } else {
          uniqueMap.set(uniqueKey, uniqueKey);
        }
      }
    });
    setMapCsv({ ...mapCsv, duplicates: dup, showMapModal: false });
    return dup;
  };
  const getUsedHeaders = (freshHeaders) => {
    const newHeaders = freshHeaders.length ? new Map(freshHeaders?.map((header) => ([header, false]))) : new Map();
    mapping.forEach(({ value }) => {
      if (headers.get(value)) {
        newHeaders.set(value, true);
      } else if (newHeaders.get(value) === false) {
        newHeaders.set(value, true);
      }
    });
    return newHeaders;
  };
  const detectUsedHeaders = (freshHeaders) => {
    setMapCsv({ ...mapCsv, headers: getUsedHeaders(freshHeaders) });
  };
  const handleFile = (file) => {
    if (file.length) {
      Papa.parse(file[0], {
        complete(results) {
          if (results) {
            results.data[0].forEach((item) => {
              const existingItem = mapping.get(item);
              if (existingItem !== undefined) {
                mapping.set(item, { ...existingItem, value: item });
              }
            });
            setMapCsv({
              ...mapCsv,
              showMapModal: true,
              headers: getUsedHeaders(results.data[0] || []),
              mapping,
              rows: results.data
            });
          }
        }
      });
      setFiles(file[0]);
      if (file[0]) {
        setUploadBox(false);
        setprogressState(initialProgressState);
      }
    }
  };
  const isOmmittedError = () => {
    let test = false;
    mapping.forEach((existingItem, key) => {
      mapping.set(key, {
        ...existingItem,
        hover: false,
        empty: !existingItem.value,
        touched: true
      });
      if (existingItem.required && !existingItem.value) {
        test = true;
      }
    });
    return test;
  };
  const uploadFiles = (passedDuplicateTest, allowDuplicates = false) => {
    if (!files) {
      toast.error(`please upload a ${model} csv before you proceed`);
      return;
    }
    if (isOmmittedError()) {
      toast.error('Please select the missing csv header in red border');
      return;
    }
    if (!passedDuplicateTest && foundDuplicates()) {
      return;
    }
    setMapCsv({ ...mapCsv, showMapModal: false, duplicates: 0 });
    setLoading(true);
    setLoaded(false);
    const mapExtraction = {};
    const rules = {};
    mapping?.forEach((mapValue, mapKey) => {
      mapExtraction[`${mapKey}`] = mapValue.value;
      if ('rules' in mapValue) {
        rules[`${mapKey}`] = mapValue.rules;
      }
    });
    mapExtraction.rules = rules;
    const formdata = new FormData();
    formdata.append('file', files);
    formdata.append('mapping', JSON.stringify(mapExtraction));
    formdata.append('allow_duplicates', allowDuplicates);
    formdata.append('username', localStorage.getItem('oga_username'));
    formdata.append('role', userRole);
    formdata.append('business_id', businessId);
    formdata.append('business_user_id', businessUserId);

    if (model === 'order') formdata.append('is_manual_order', isManualOrder);
    const widthInterval = setInterval(() => {
      setprogressState((state) => ({ ...state, width: (state.width + 1) % 100 }));
    }, 300);
    axios.post(`/upload/${endpoint}`, formdata)
      .then((res) => {
        const response = res.data;
        if (response.fields && response.fields.length) {
          setCsvData(response.fields);
          setCsvHeader(response.rules);
          SetSuccessPopper(true);
        } else {
          toast.success(model === 'order' ? 'Upload is being processed' : response.status);
        }
        clearInterval(widthInterval);
        setprogressState({ state: 'ok', width: 100 });
        if (setData) {
          setData(response.data);
        }
        // refetch();
      })
      .catch((err) => {
        toast.error(err?.message);
        clearInterval(widthInterval);
        setprogressState({ state: 'err', width: 100 });
      })
      .finally(() => {
        if (successPopper) {
          setLoading(false);
          setLoaded(true);
          setTimeout(() => setMapCsv({ ...mapCsv, showMapModal: false, showActionRequestModal: true }), 2000);
        }
        if (model === 'order') {
          setTimeout(() => navigate('/cart', { state: { prevLocation: '/orders'}}), 4000);
        }
      });
  };

  useEffect(() => {
    if (csvData.length) {
      const _csvErrors = csvData?.map((field) => field?.map((item) => item));
      setCsvErrors(_csvErrors);
    }
  }, [csvData]);
  const getHeaderOptions = () => {
    const optionsBox = { name: '', label: '', options: [] };
    const headersToArray = ['', ...getHeadersToArray()];
    headersToArray.forEach((header) => {
      optionsBox.options.push(header || noData);
    });
    return optionsBox;
  };
  const handleMapHeader = (key, correspondingKeyEvent) => {
    const existingItem = mapping.get(key);
    const { value } = correspondingKeyEvent.target;
    mapping.set(key, {
      ...existingItem,
      value: value === noData ? '' : value,
      hover: false,
      empty: !value,
      touched: true,
    });
    setMapCsv({ ...mapCsv, mapping });
    detectUsedHeaders(getHeadersToArray());
  };
  const setHover = (key, status) => {
    const existingItem = mapping.get(key);
    mapping.set(key, { ...existingItem, hover: status });
    setMapCsv({ ...mapCsv, mapping });
  };
  const renderMapableHeaders = () => {
    const selectables = [];
    let x = 0;
    mapping.forEach((value, key) => {
      x++;
      const row = mapping.get(key);
      selectables.push(
        <Grid xs={12} md={6}>
          <SelectGrid
            item
            onMouseEnter={() => setHover(key, true)}
            onMouseLeave={() => setHover(key, false)}
            key={value + x}
            container
            alignItems="center"
          >
            <MapGrid style={{ color: '#A8A8A8' }} xs={12} md={4}>
              { key }
            </MapGrid>
            <Grid
              xs={12}
              md={8}
              style={{
                borderBottom: row.touched && row.required && row.empty ? '5px solid red' : '#fff',
                marginBottom: isSmall && '2rem'
              }}
            >
              <ReturnSelectField
                field={getHeaderOptions()}
                value={row.value}
                showCheckBox={false}
                handleCreditDaysOpen={() => ({})}
                fullWidth
                handleChange={(correspondingKeyEvent) => handleMapHeader(key, correspondingKeyEvent)}
                header={key}
              />
            </Grid>
          </SelectGrid>
        </Grid>
      );
    });
    return selectables;
  };
  const getUsedHeadersView = () => {
    const headersToArray = getHeadersToArray();
    return (
      <Ul>
        { headersToArray?.map((header) => {
          const strikeThrough = headers.get(header);
          return (
            <Li
              key={header}
              strikeThrough={strikeThrough}
            >
              { strikeThrough ? <strike>{ header }</strike> : header }
            </Li>
          );
        })}
      </Ul>
    );
  };
  const handleShowExperimentalFields = () => {
    const showExperimentalFields = !mapCsv.showExperimentalFields;
    experimentalFields.forEach((field) => {
      if (showExperimentalFields) {
        mapping.set(field[0], field[1]);
      } else {
        mapping.delete(field[0]);
      }
    });
    setMapCsv({ ...mapCsv, showExperimentalFields, mapping });
  };
  const renderCSVMapDialog = () => (
    <Grid container data-testid={`erp-uat-${lowerCaseModel}-mapping-modal`}>
      <MainMapGrid item xs={12} md={12} lg={9}>
        <TextWrapper item>
          <PrimaryTitle variant="h5">
            { role === 'oga-pharmacy-admin' && !!experimentalFields.length && (
            <Checkbox
              checked={mapCsv.showExperimentalFields}
              onChange={() => handleShowExperimentalFields()}
              disabled={loading}
              name="terms"
              color="primary"
            />
            ) }
            Map Fields
          </PrimaryTitle>
          <SecondaryTitle item>
            Match the fields from your file to their related PharmIQ fields to facilitate data migration.
          </SecondaryTitle>
        </TextWrapper>
        <br />
        <br />
        <Grid container>
          { renderMapableHeaders() }
        </Grid>
        <ButtonWrapper container justifyContent="flex-end" spacing={2}>
          <Grid item>
            <CsvOutlinedCustomButton data-testid="erp-uat-close-mapping-modal" onClick={closeDialog}>
              Cancel
            </CsvOutlinedCustomButton>
          </Grid>

          <Grid item>
            <CsvFilledCustomButton data-testid="erp-uat-save-mapping-data" onClick={() => uploadFiles(false)}>
              Save
            </CsvFilledCustomButton>
          </Grid>
        </ButtonWrapper>
      </MainMapGrid>
      <MapInfoGrid item xs={12} md={12} lg={3}>
        Select the source header from the CSV file you’re uploading to match their related PharmIQ field in the dropdown
        <br />
        { getUsedHeadersView() }
      </MapInfoGrid>
    </Grid>
  );
  const renderNoticeDialog = () => (
    <>
      <br />
      <DialogTitle id="alert-dialog-slide-title"><b>Duplicates Notice</b></DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-slide-description">
          Your file contains
          {' '}
          {duplicates}
          {' '}
          duplicate
          {duplicates > 1 ? 's' : ''}
          . What do you want to do?
        </DialogContentText>
      </DialogContent>
      <Grid container justifyContent="center" style={{ padding: '10px' }}>
        <Grid item>
          <CSVNoIconCancelButton
            item
            onClick={closeNoticeDialog}
          >
            <CSVNoIconButtonText>
              Cancel
            </CSVNoIconButtonText>
          </CSVNoIconCancelButton>
        </Grid>

        <Grid item>
          <CSVNoIconUploadButton
            style={{
              marginRight: '20px',
              backgroundColor: '#424242',
              color: '#FAF33E'
            }}
            item
            onClick={() => uploadFiles(true)}
          >
            <CSVNoIconButtonText>
              Remove
            </CSVNoIconButtonText>
          </CSVNoIconUploadButton>
        </Grid>

        <Grid item>
          <CSVNoIconUploadButton
            item
            onClick={() => uploadFiles(true, true)}
          >
            <CSVNoIconButtonText>
              Proceed
            </CSVNoIconButtonText>
          </CSVNoIconUploadButton>
        </Grid>

      </Grid>
      <br />
      <br />
    </>
  );

  const renderUploadSuccessActionDialog = () => (
    <>
      <br />
      <DialogTitle id="alert-dialog-slide-title"><b>Your action was completed</b></DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-slide-description">
          What do you want to do next?
        </DialogContentText>
      </DialogContent>
      <Grid container justifyContent="center" style={{ padding: '10px' }}>
        <Grid item>
          <CSVNoIconCancelButton
            item
            onClick={() => navigate((model === 'order' ? (isManualOrder ? '/manual-cart' : '/cart') : `/${backRoute}`))}
          >
            <CSVNoIconButtonText>
              {model === 'order' ? 'Proceed to checkout' : 'Back' }
            </CSVNoIconButtonText>
          </CSVNoIconCancelButton>
        </Grid>

        <Grid item>
          <CSVNoIconUploadButton
            style={{
              marginRight: '20px',
              backgroundColor: '#424242',
              color: '#FAF33E'
            }}
            item
            onClick={() => setMapCsv({ ...mapCsv, showMapModal: true, showActionRequestModal: false })}
          >
            <CSVNoIconButtonText>
              Modify
            </CSVNoIconButtonText>
          </CSVNoIconUploadButton>
        </Grid>

        <Grid item>
          <CSVNoIconUploadButton
            item
            onClick={closeDialog}
          >
            <CSVNoIconButtonText>
              Upload Another
            </CSVNoIconButtonText>
          </CSVNoIconUploadButton>
        </Grid>

      </Grid>
      <br />
      <br />
    </>
  );
  const CsvErrorList = Object.entries(csvHeader)?.map(([item, _val], idx) => (
    <th key={idx}>
      {item}
    </th>
  ));
  const errorMessage = csvErrors?.map((items) => items?.map((item) => item.errors?.map((errorText) => errorText)));
  return (
    <>
      <Grid data-testid={`erp-uat-select-${lowerCaseModel}-file-modal`}>
        <Grid>
          {
              uploadBox ? (
                <>
                  <UploadFileBar
                    background="#fff"
                    csvName={`${toTitleCase(model)} upload template.csv`}
                    csvSize="1 MB"
                    action="download"
                    getTemplate={getTemplate}
                  />
                  <DropZone
                    handleFile={handleFile}
                    model={lowerCaseModel}
                  />
                </>
              ) : (
                <UploadFileWrapper>
                  <DownloadIconWrapper container direction="column" justifyContent="center" alignItems="center">
                    <Grid item>
                      <DownloadIconImage src={DownloadIcon} />
                    </Grid>
                  </DownloadIconWrapper>
                  <br />
                  <ModifyGrid onClick={modifyCSV}>
                    <ModifyCSVLabel>
                      Modify CSV
                    </ModifyCSVLabel>
                  </ModifyGrid>
                  <br />
                  <UploadFileBar
                    margin="0px"
                    background="#F0F0F0"
                    csvName={files.name.slice(0, 100)}
                    csvSize={`${files.size / (1024 ** 2)} KB`}
                    action="delete"
                    onDelete={() => {
                      setMapCsv({ ...mapCsv, mapping: new Map(fields) });
                      setUploadBox(true);
                    }}
                    percent={progressState.width}
                    state={progressState.state}
                  />
                  <br />
                  {' '}
                </UploadFileWrapper>
              )
            }
        </Grid>
      </Grid>

      <Dialog
        open={!!duplicates}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => ({})}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        maxWidth="sm"
        fullWidth
      >
        { renderNoticeDialog() }
      </Dialog>
      <Dialog
        open={successPopper}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => ({})}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        maxWidth="xl"
        style={{ marginTop: '60px' }}
        fullWidth
      >
        <CsvDialogHeader>
          CSV Upload Rule
        </CsvDialogHeader>
        <CsvDialogBody id="scroll-dialog-description" style={{ overflowX: 'auto' }}>
          <table style={{ justifyContent: 'space-between' }}>
            <tr style={{ fontSize: '1.1rem', paddingRight: '10px' }}>
              {CsvErrorList}
            </tr>
            <tbody>
              {csvErrors.length && csvErrors.map((csvError, index) => (
                <tr>
                  {csvError?.map((item, idx) => (
                    <Tooltip title={errorMessage[index][idx]} placement="top">
                      <TableRow key={item.data}>
                        <div
                          style={{
                            backgroundColor: errorMessage[index][idx].length >= 1 ? '#FF0000' : 'white',
                            color: errorMessage[index][idx].length >= 1 ? '#FFFFFF' : '000000',
                            padding: item.data[idx] == null ? '15px' : '0px',
                            margin: '0px',
                            fontSize: '1rem'
                          }}
                        >
                          {item.data}
                        </div>
                      </TableRow>
                    </Tooltip>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </CsvDialogBody>
        <DialogActions>
          <CSVNoIconCancelButton
            item
            onClick={closeCsvRuleDialog}
            style={{
              backgroundColor: '#FAF33E',
              color: '#424242'
            }}
          >
            <CSVNoIconButtonText>
              Close
            </CSVNoIconButtonText>
          </CSVNoIconCancelButton>
        </DialogActions>
      </Dialog>
      <Dialog
        open={showActionRequestModal}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => ({})}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        maxWidth="sm"
        fullWidth
      >
        { renderUploadSuccessActionDialog() }
      </Dialog>

      <CDialog
        open={showMapModal}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => ({})}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        maxWidth="lg"
        fullWidth
      >
        { renderCSVMapDialog() }

      </CDialog>
    </>
  );
};

MapCsvDialog.propTypes = {
  fields: Proptypes.instanceOf(Object).isRequired,
  experimentalFields: Proptypes.instanceOf(Object),
  model: Proptypes.string.isRequired,
  endpoint: Proptypes.string.isRequired,
  backRoute: Proptypes.string.isRequired,
};
MapCsvDialog.defaultProps = {
  experimentalFields: []
};
export default MapCsvDialog;
