import {
  TableCell, Checkbox, Grid, TableRow
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { css } from '@emotion/react';

export const MainTableRow = styled(TableRow)`
  width: 100%;
  align-items: center;

  & > * {
    border-bottom: unset;
  }
  :nth-child(2n) {
    background: #F3F9FF;
  }
`;

export const BodyCell = styled(TableCell)`
  text-transform: capitalize;
  font-size: 1.2rem;
  line-height: 2.8;
  padding: 10px;
  font-size: .9rem;
`;

const isHybrid = css`
  background-color: #E1FFE2;
  color: #07DE10;
`;

const notIsHybrid = css`
  background-color: #FFDAD6;
  color: #FA3636;
`;

const getStyles = ({ status }) => {
  switch (status) {
    case 'YES':
      return isHybrid;
    default:
      return notIsHybrid;
  }
};

export const ColorGrid = styled(Grid)`
  border-radius: 7px;
  text-transform: capitalize;
  font-size: .85rem;
  font-weight: 500;
  justify-content: center;
  padding: 1px 0;

  ${getStyles}
`;

export const CustomCheckbox = styled(Checkbox)`
  color: #424242;
  & .MuiSvgIcon-fontSizeSmall {
    font-size: 1.4rem;
  }
`;
